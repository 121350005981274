import React, { useEffect, useState } from "react";
import Header from "../Header";
import { useNavigate, useParams } from "react-router-dom";
import { Toast, Userlogin } from "../Utils/general-function";
function Changepassword(props) {
  const [input, setInput] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  
  const handleChange = (e) => {
    let value = e.target.value;
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: value,
    }));
  };
  const Validate = () => {
    let errors = {};
    let isValid = true;
    if (!input["current"]) {
      isValid = false;
      errors["current"] = "This feild is required";
    }
    if (!input["New"]) {
      isValid = false;
      errors["New"] = "This feild is required";
    }
    setErrors(errors);

    return isValid;
  };
  useEffect(() => {
    if (!Userlogin()) {
      navigate("/Login");
      return;
    }
  });
  const handleSubmit = () => {
    setLoading(true);
    if (Validate()) {
      var param = {
        OldPassword: input.current,
        NewPassword: input.New,
      };
      const formBody = Object.entries(param)
        .map(
          ([key, value]) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(value)
        )
        .join("&");
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
        },
        body: formBody,
      };
      fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/DealerRegistration/ChangePassword",
        requestOptions
      )
        .then((response) => response.json())
        .then((res) => {
          setLoading(false);
          Toast(res.Success, res.Message, res.Message);
          if (res.Success) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    }
  };
  function ShowHidePassword() {
    var pwdtxt = document.getElementById("passwordtxt");
    var pwdicon = document.getElementById("passwordicon");
    if (pwdtxt.type === "password") {
      pwdtxt.type = "text";
      pwdicon.textContent = "visibility_off";
    } else {
      pwdtxt.type = "password";
      pwdicon.textContent = "visibility";
    }
  }
  return (
    <div>
      <Header />
      <div
        className="content m-4 px-4 pb-4"
        style={{
          boxShadow: "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px",
          borderRadius: "10px",
        }}
      >
        <div className="text-center">
          <h2 className="page-title">Change Password</h2>
        </div>
        <div className="form-div">
          <label className="form-label">Current Password</label>
          <input
            type="password"
            className="form-control"
            name="current"
            // value={input.name}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.name}</span>
        </div>

        <div className="form-div" style={{ position: "relative" }}>
          <label className="form-label">New Password</label>
          <input
            type="password"
            className="form-control"
            name="New"
            id="passwordtxt"
            // value={input.Mobileno}
            onChange={handleChange}
          />
          <span
            className="material-symbols-outlined eyespan"
            id="passwordicon"
            onClick={() => ShowHidePassword()}
          >
            visibility
          </span>
          <span className="text-danger">{errors.New}</span>
        </div>
        <div className="text-center pt-3">
          <button type="submit" className="submitbtn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

export default Changepassword;
