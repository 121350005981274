import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../Header";
import Loader from "../../Utils/Loader";
import Select from "react-select";
import { customStyles } from "../../Utils/general-function";
import Innerlistcommon from "./Innerlistcommon";
import { Shareurl, Userlogin } from "../../Utils/general-function";
import { useSearch } from "../../Searchcontext";
import NoCars from "../../Utils/NoCars";
import Defaultpreview from "../../Utils/Defaultpreview";


function Specifiedcarcatlogue(props) {
  const [loading, setLoading] = useState(true);
  const [moedlist, setModelist] = useState([]);
  const { Specificcat, setSpecificcat } = useSearch();
  const navigate = useNavigate();
  const countRef = useRef(Specificcat.count); // set 0 value for showing div for first page load
  useEffect(() => {
    setLoading(true);
    if (!Userlogin()) {
      navigate("/Login");
      return;
    }
    fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Common/Allactivemodel")
      .then((response) => response.json())
      .then((res) => {
        setModelist(res.Data);
        setLoading(false);
      });
  }, []);

  const handleDropchange = (obj) => {
    setSpecificcat((prevValue) => ({
      ...prevValue,
      Modelobj: obj,
    }));
    Submit(obj.Value);
  };
  const userid = localStorage.getItem("Ca1itd");
  const Submit = (selectedmodel) => {
    try {
      fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/Carmaster/GetdetailsofCarsbyRandomidModelid?Randomid=" +
          userid +
          "&modelid=" +
          selectedmodel
        // Specificcat.Modelobj.Value
      )
        .then((response) => response.json())
        .then((res) => {

          setSpecificcat((prevValue) => ({
            ...prevValue,
            resultlist: res.Data,
            count:1
          }));
          // setCarlist(res.Data)
          setLoading(false);
          countRef.current = 1;
        });
    } catch (error) {
      console.log(error);
    }
  };
  const Onchange = (e) => {
    setSpecificcat((prevValue) => ({
      ...prevValue,
      Branding: e.target.value,
    }));
  };
  return (
    <div className="page-space">
      <Header />
      <div className="text-center">
        <h2 className="page-title">Model-Oriented Catalog</h2>
      </div>
      {loading ? <Loader /> : null}
      {/*--------------- Search Module---------------------- */}
      <div className="search-div mt-2">
        <Select
          placeholder="Select Model"
          name="Model"
          value={Specificcat.Modelobj}
          options={moedlist}
          getOptionLabel={(option) => option.Text}
          getOptionValue={(option) => option.Value}
          onChange={(obj) => handleDropchange(obj, "Model")}
          closeMenuOnSelect={true}
          blurInputOnSelect={false}
          isSearchable={true}
          styles={customStyles}
          className="w-100"
        ></Select>
        {/* <button className="add-citybtn" onClick={() => Submit()}>
          <span className="material-symbols-outlined">arrow_forward</span>
        </button> */}
      </div>

      {/* ------------------Share Module------------------ */}
      {countRef.current === 0 ? (
        <Defaultpreview img="assets/images/search.png" msg="Quickly find cars in your account by model. Share the list of selected models as a catalog seamlessly!!"/>
      ) : (
        <>
          {Specificcat.resultlist.length === 0 ? (
            <NoCars />
          ) : (
            <>
              <div className="p-3">
                <div className="text-center">
                  <div class="form-div">
                    <div>
                      <div class="form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="radio1"
                          name="optradio"
                          value="W_hB"
                          onChange={Onchange}
                          checked={
                            Specificcat.Branding === "W_hB" ? true : false
                          }
                        />
                        <label class="form-check-label px-1" for="radio1">
                          Share With Branding
                        </label>
                      </div>
                      <div class="form-check-inline">
                        <input
                          type="radio"
                          class="form-check-input"
                          id="radio2"
                          name="optradio"
                          value="Wo_hB"
                          onChange={Onchange}
                          checked={
                            Specificcat.Branding === "Wo_hB" ? true : false
                          }
                        />
                        <label class="form-check-label px-1" for="radio2">
                          Share Without Branding
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* ---------------Result module--------------------- */}

              <Innerlistcommon catloguelist={Specificcat.resultlist} />

              <button
                className="sharebtn fixshare"
                onClick={(e) => {
                  Shareurl(
                    "/#/Specficatlogueshare/" +
                      userid +
                      "/" +
                      Specificcat.Modelobj.Value +
                      "/" +
                      Specificcat.Branding
                  );
                }}
              >
                Share catalogue
                <span>
                  <img src="assets/images/share.png" alt="" />
                </span>
              </button>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default Specifiedcarcatlogue;
