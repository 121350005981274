import React, { useState, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import Resizer from "react-image-file-resizer";
let files;
function CatimgUpload(props) {
  const [src, setsrc] = useState();
  const [show, setshow] = useState();
  const [cropper, setCropper] = useState();
  const [name, setName] = useState("");
  const [error, seterror] = useState("");
  const [cropResult, setcropResult] = useState("assets/images/upload-img.png");

  function Validate(target) {
    var Isvalid = true;
    if (props.datafromParent) {
      if (target.files[0].size > 1024 * 1024 * 10) {
        Isvalid = false;
        seterror("Sorry, you can upload photo up to 10 MB.");
      }
    }
    return Isvalid;
  }

  function onChange(e) {
    try {
      if (!Validate(e.target)) return;
  
      const file = e.target.files[0];
      if (!file) return;
  
      seterror("");
      setName(file.name);
  
      const reader = new FileReader();
      reader.onload = () => {
        const data = {
          url: reader.result,
          name: name,
        };
        if (file.type === "application/pdf") {
          props.parentCallback(data);
        } else {
          const img = new Image();
          img.onload = () => {
            Resizer.imageFileResizer(
              file,
              img.width,
              img.height,
              "JPEG",
              25,
              0,
              (resizedFile) => {
                setsrc(resizedFile);
                setshow(true);
              },
              "base64"
            );
          };
          img.onerror = (error) => {
            throw new Error("Failed to load image: " + error.message);
          };
          img.src = reader.result;
          setshow(true);
        }
      };
      reader.onerror = (error) => {
        throw new Error("Error reading file: " + error.message);
      };
      reader.readAsDataURL(file);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  }
  
  function handlemodal() {
    setshow(!show);

    $('input[type="file"]').val("");
  }
  function cropImage() {
    if (typeof cropper.getCroppedCanvas() === "undefined") {
      return;
    }
    setcropResult(cropper.getCroppedCanvas().toDataURL());

    setshow(false);
    $('input[type="file"]').val("");

    const data = {
      url: cropper.getCroppedCanvas().toDataURL(),
      name: name,
    };
    props.parentCallback(data);
  }

  return (
    <div>
      <div className="row strip-div">
        <div className="col-12">
          {/* <label htmlFor="image"> */}
          <input
            type="file"
            name="image"
            id="image"
            accept="image/*,.pdf,application/pdf"
            className="form-control d-none"
            onChange={onChange}
          />
          <label className="catlg-addimg" htmlFor="image">
            <span className="mticon">
              <span className="material-symbols-outlined">add_a_photo</span>
            </span>{" "}
            Upload document
          </label>
        </div>
        <div className="text-danger">{error}</div>
      </div>
      <Modal
        show={show}
        onHide={handlemodal}
        className="crp-mdl"
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Cropper
          style={{ width: "100%", height: "100%" }}
          //  initialAspectRatio={1}
          preview=".img-preview"
          guides={false}
          background={false}
          movable={false}
          zoomable={false}
          src={src}
          cropBoxResizable={true}
          onInitialized={(instance) => {
            setCropper(instance);
            
          }}
          viewMode={1}
          dragMode="move"
          minCropBoxWidth={100}
          minCropBoxHeight={100}
          autoCropArea={1}
        />
        <button
          onClick={cropImage}
          className="submitbtn mb-0"
          style={{ borderRadius: "0" }}
        >
          Save Image
        </button>
      </Modal>
    </div>
  );
}

export default CatimgUpload;
