import React, { useState, useEffect } from "react";
const NoInternetConnection = (props) => {
  // state variable holds the state of the internet connection
  const [isOnline, setOnline] = useState(true);

  // On initization set the isOnline state.
  useEffect(() => {
    setOnline(navigator.onLine);
  }, []);

  // event listeners to update the state
  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });
   


 
  // if user is online, return the child component else return a custom component
  if (isOnline) {
    return props.children;
  } else {
    return (
      <div className="text-center">
        <h3 className="text-center no-connect-internet-title my-4">
          No internet connection
        </h3>
        <div className="clearfix"></div>
        {/* <div className="no-connect-internet">
          <img src={calc33} alt="No internet" />
        </div> */}
        <div className="clearfix"></div>
        <p className="no-connect-internet-text">
          Please re-connect to the internet to continue...
        </p>
        <div className="clearfix"></div>
        {/* <button onClick={()=>click()}>Try again</button> */}
        
      </div>
    );
  }
};

export default NoInternetConnection;