import React from "react";
import { Link } from "react-router-dom";
import RemainingPageTitle from "../../Utils/RemainingPageTitle";
import NoCars from "../../Utils/NoCars";

// for displaying coomon list in share catalogue
function Commonsharelist({ catloguelist, DealerData, Branding }) {
  // const catloguelist=props.DataFromParent.list
  // const DealerData=props.DataFromParent.data
  return (
    <>
      {catloguelist.length === 0 ? (
        <NoCars />
      ) : (
        catloguelist.map((list) => (
          <div className="car-div" key={list.carid}>
            {/* <Link to={"/CatDetails/"+list.Rand}> */}
            <div>
              <div className="car-radius position-relative">
                <img
                  src="assets/images/abslogo.png"
                  className="abs-logo"
                  alt=""
                  style={{maxWidth: "15%"}}
                />
                <Link
                  to={
                    "/CatDetails/" +
                    list.Rand +
                    "/" +
                    DealerData.DealerRand +
                    "/" +
                    Branding
                  }
                >
                  <img src={list.carphoto} className="w-100" alt="" />
                </Link>
                <div className="car-smdetails">
                  <span className="abs-span">{list.fueltypeid}</span>

                  <RemainingPageTitle dataFromParent={list} />
                  <div className="car-km justify-content-between">
                    <div>
                      {/* <img src="assets/images/car-num.svg" alt="" />
                        <span>{"****" + list.carnumber.substring(6)}</span> */}
                      <img
                        src="assets/images/car-km.svg"
                        alt=""
                        className="ms-2"
                      />
                      <span>{list.km} km</span>
                    </div>
                    <div className="d-inline-flex align-items-center">
                      <Link
                        to={
                          "/CatDetails/" +
                          list.Rand +
                          "/" +
                          DealerData.DealerRand +
                          "/" +
                          Branding
                        }
                      >
                        <img src="assets/images/view-more.png" alt="" />
                      </Link>
                      {Branding ==="W_hB"?
                      (
                        <a href={"tel:" + DealerData.Mobileno} className="ms-2">
                          <img src="assets/images/call-now.png" alt="" />
                        </a>
                      ):null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </Link> */}
          </div>
        ))
      )}
    </>
  );
}

export default Commonsharelist;
