import React from "react";

function Footer(props) {
  const DealerData = props.dataFromParent;
  const othernumbers = DealerData.Mobileno1?.split("/");
  return (
    <div>
      <div className="status-bottom mt-2">
        <h3 style={{ color: "red" }}>{DealerData.Firmname}</h3>
        {/* <h3>{apiData.Firmname}</h3> */}

        <div className="d-flex align-items-center justify-content-center flex-wrap">
          <div className="d-inline-flex align-items-center">
          <img src="assets/images/call.png" style={{ width: "18px" }} alt="" />{" "}
          <a href={"tel:" + DealerData.Mobileno} className="m-2">
            <h4>
              {DealerData.Mobileno}
            </h4>
          </a>
          </div>
          {DealerData.Mobileno1
            ? othernumbers.map((data) => (
                <div className="d-inline-flex align-items-center">
                  <img
                    src="assets/images/call.png"
                    style={{ width: "18px" }}
                    alt=""
                  />{" "}
                  <a href={"tel:" + data} className="m-2">
                    <h4>{data}</h4>
                  </a>
                </div>
              ))
            : " "}
        </div>

        <a href={DealerData.nGpslink} style={{ color: "black" }} id="footeraddress">
          <div className="d-flex align-items-center justify-content-center">
            <img
              src="assets/images/address.png"
              style={{ width: "20px" }}
              alt=""
            />

            <label className="ps-2">{DealerData.Address}</label>
          </div>
        </a>
      </div>
    </div>
  );
}

export default Footer;
