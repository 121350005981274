import React from "react";
import {getOrdinal} from "./general-function"

function Info(props) {
  const details = props.dataFromParent;
  const Features=details?.Listidjson ||[];

  
  return (
    <div>
      <div className="car-grid">
        {/* <div className="car-details">
              <div className="myflex">
                <span>
                  <img src="assets/images/registrationyear.svg" alt="" />
                </span>
                <span className="title">Registration Year</span>
              </div>
              <div className="info">{details.modelyear}</div>
            </div>
            <div className="car-details">
              <div className="myflex">
                <span>
                  <img src="assets/images/car-km.svg" alt="" />
                </span>
                <span className="title">Kilometer</span>
              </div>
              <div className="info">{details.km}</div>
            </div> */}
        <div className="car-details">
          <div className="myflex">
            <span>
              <img src="assets/images/ownership.svg" alt="" />
            </span>
            <span className="title">Ownership</span>
          </div>
          <div className="info">{getOrdinal(details?.owner)}  &nbsp;Owner</div>
        </div>
        <div className="car-details">
          <div className="myflex">
            <span>
              <img src="assets/images/fuel.svg" alt="" />
            </span>
            <span className="title">Fuel Type</span>
          </div>
          <div className="info">{details?.fueltypeid}</div>
        </div>
        <div className="car-details">
          <div className="myflex">
            <span>
              <img src="assets/images/color.svg" alt="" />
            </span>
            <span className="title">Color</span>
          </div>
          <div className="info">{details?.colour}</div>
        </div>
        <div className="car-details">
          <div className="myflex">
            <span>
              <img src="assets/images/seats.svg" alt="" />
            </span>
            <span className="title">Seating Capacity</span>
          </div>
          <div className="info">{details?.seatingcapicity}</div>
        </div>
        <div className="car-details">
          <div className="myflex">
            <span>
              <img src="assets/images/gear.png" alt="" />
            </span>
            <span className="title">Gear</span>
          </div>
          <div className="info">{details?.gear}</div>
        </div>
        {details?.nSunRoof==="Yes"&&
        <div className="car-details">
          <div className="myflex">
            <span>
              <img src="assets/images/sunroof.png" alt="" />
            </span>
            <span className="title">Sunroof</span>
          </div>
          <div className="info">{details?.nSunRoof}</div>
        </div>}
        <div className="car-details">
          <div className="myflex">
            <span>
              <img src="assets/images/insurance.svg" alt="" />
            </span>
            <span className="title">Insurance</span>
          </div>
          <div className="info">
            {details?.insurance === "Yes"
              ? details?.insurance + "-" + details?.nInsurancetype
              : details?.insurance}
          </div>
        </div>
        {details?.insurance === "Yes" ? (
          <>
            <div className="car-details">
              <div className="myflex">
                <span>
                  <img src="assets/images/registrationyear.svg" alt="" />
                </span>
                <span className="title">Insurance End Date</span>
              </div>
              <div className="info">{new Date(details?.insuranceendtdate).toLocaleDateString('es-CL')}</div>
            </div>
          </>
        ) : null}
      </div>
      {Features.length===0?null:
      <div className="car-details my-2" id="features">
        <ul className="car-features">
          {Features.map((list)=>(
          <li key={list.value}>{list.label}</li>))}
        </ul>
      </div>}
    </div>
  );
}

export default Info;
