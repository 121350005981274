import React, { useEffect, useState } from "react";
import Header from "../Header";
import Loader from "../Utils/Loader";
import { Modal } from "react-bootstrap";

function MessageTemplate(props) {
  const [templates, setTemplates] = useState([]);
  const [selected, setSelected] = useState({});
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [input, setInput] = useState({});
  const [errors, setErrors] = useState({});
  const [message, setMessage] = useState(""); // for updating message

  useEffect(() => {
    try {
      const header = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
        },
      };
      fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/MessageTemplate/AllMessageTemplates",
        header
      )
        .then((response) => response.json())
        .then((res) => {
          setTemplates(res.Data);
          setLoading(false);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  }, []);

  const Onchange = (e) => {
    const Target = e.target;
    if (Target.name === "name") {
      setMessage(
        selected.messagetemplate.replace(/\[Customer_Name\]/g, Target.value)
      );
    }

    setInput((prevInput) => ({
      ...prevInput,
      [Target.name]: Target.value,
    }));
  };

  const OnModel = (obj) => {
    setSelected(obj);
    setMessage(obj.messagetemplate);
    setErrors({});
    setInput({});
    setShow(true);
  };

  const validate = () => {
    let errors = {};
    let isValid = true;

    if (!input["mobileno"]) {
      isValid = false;
      errors["mobileno"] = "Mobile  Number is required.";
    }
    if (typeof input["mobileno"] !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(input["mobileno"] || input["mobileno"].length !== 10)) {
        isValid = false;
        errors["mobileno"] = "Enter valid mobile number.";
      }
    }
    // if (!input["name"]) {
    //   isValid = false;
    //   errors["name"] = "Name is required.";
    // }

    setErrors(errors);

    return isValid;
  };
  const OnShare = () => {
    if (validate()) {
      let finaltext = message;
      if (!input["name"]) {
        finaltext = message.replace(/\[Customer_Name\]/g, "");
      }

      const text = encodeURIComponent(finaltext);
      const iphone = window.navigator.userAgent.includes("Mac");
      if (iphone) {
        window.location.href = `https://wa.me/+91${input.mobileno}?text=${text}`;
      } else {
        window.location.href = `intent://send?phone=+91${input.mobileno}&text=${text}#Intent;scheme=whatsapp;package=com.whatsapp.w4b;end`;
      }
    }
  };

  return (
    <div>
      <Header />
      {loading && <Loader />}
      <div className="content">
        <div className="text-center">
          <h2 className="page-title mb-4">Templates</h2>
        </div>
        {templates.length === 0 ? (
          <div className="text-center mt-4">
            <h3 className="car-docstitle border-0">No Templates found!</h3>
          </div>
        ) : (
          templates.map((list) => (
            <div className="car-docsdiv" key={list.random}>
              <div className="d-flex align-items-center flex-nowrap">
                <img src="assets/images/message.png" alt="" />
                <div className="flex-grow-1 px-3">
                  <h3>{list.title}</h3>
                </div>
                <div className="car-docscenter">
                  <button className="cardoc-btn">
                    <img
                      src="assets/images/sharet.png"
                      alt="share"
                      style={{ width: "25px" }}
                      onClick={(e) => OnModel(list)}
                    />
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      <Modal
        show={show}
        onHide={(e) => setShow(!show)}
        id="resultpopup"
        className="modal"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <h5>{selected.title}</h5>
        </Modal.Header>
        <Modal.Body>
          <div style={{ maxHeight: "200px", overflowY: "auto" }}>
            <p dangerouslySetInnerHTML={{ __html: message }} id="message"></p>
          </div>
          <div className="form-div">
            <input
              type="text"
              className="form-control"
              name="name"
              placeholder="Enter Customer Name"
              onChange={Onchange}
              value={input.name}
            />
            <span className="text-danger">{errors.name}</span>
          </div>
          <div className="form-div">
            <input
              type="tel"
              className="form-control"
              name="mobileno"
              placeholder="Enter Customer Mobile No."
              onChange={Onchange}
              value={input.mobileno}
            />
            <span className="text-danger">{errors.mobileno}</span>
          </div>
          <div className="text-center pt-3 mb-3">
            <button
              type="submit"
              className="submitbtn"
              onClick={(e) => OnShare()}
            >
              Share
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MessageTemplate;
