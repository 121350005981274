import React, { useState, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import Resizer from "react-image-file-resizer";
let files;
function Upload_logo(props) {
  const [src, setsrc] = useState();
  const [show, setshow] = useState();
  const [cropper, setCropper] = useState();
  const [name, setName] = useState("");
  const [error, seterror] = useState("");
  const [cropResult, setcropResult] = useState("assets/images/upload-img.png");

  function Validate(target) {
    var Isvalid = true;

    if (target.files[0].size > 1024 * 1024 * 10) {
      Isvalid = false;
      seterror("Sorry, you can upload photo up to 10 MB.");
    }
    return Isvalid;
  }

  useEffect(() => {
    setcropResult(
      props.dataFromParent
        ? props.dataFromParent.split(",")[0]
        : "assets/images/upload-img.png"
    );
  }, [props.dataFromParent]);
  function onChange(e) {
    const files = e.dataTransfer?.files || e.target?.files;
    if (files) {
      if (Validate(e.target)) {
        seterror("");

        setName(files[0].name);

        const reader = new FileReader();
        reader.onload = () => {
          setsrc(reader.result);

          setshow(true);
        };
        reader.readAsDataURL(files[0]);
      }
    }
  }
  function handlemodal() {
    setshow(!show);

    $('input[type="file"]').val("");
  }
  function cropImage() {
    if (typeof cropper.getCroppedCanvas() === "undefined") {
      return;
    }
    setcropResult(cropper.getCroppedCanvas().toDataURL());
    setshow(false);
    $('input[type="file"]').val("");

    const data = {
      url: cropper.getCroppedCanvas().toDataURL(),
      name: name,
    };
    props.parentCallback(data);
  }
  return (
    <div>
      <div className="strip-div">
        <div className="col-12 text-center">
          {/* <label htmlFor="image"> */}
          <input
            type="file"
            name="image"
            id="image"
            accept=".jpg,.jpeg,.png"
            className="form-control d-none"
            onChange={onChange}
          />
          <label className="image-upload" htmlFor="image">
            Click here to Upload Logo
          </label>
          {/* <img src={cropResult} className="child-image" alt="Anchoredge" /> */}
          {/* </label> */}
        </div>
        <div className="text-danger">{error}</div>
      </div>
      <Modal
        show={show}
        onHide={handlemodal}
        className="crp-mdl"
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Cropper
          style={{ width: "100%", height: "auto" }}
          initialAspectRatio={1}
          preview=".img-preview"
          guides={false}
          background={false}
          movable={true}
          zoomable={true}
          src={src}
          cropBoxResizable={true}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          viewMode={1}
          dragMode="move"
          // autoCropArea={1}
        />
        <button
          onClick={cropImage}
          className="submitbtn mb-0"
          style={{ borderRadius: "0" }}
        >
          Crop Image
        </button>
      </Modal>
    </div>
  );
}

export default Upload_logo;
