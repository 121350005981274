import React, { useState, useEffect } from "react";
import {
  Userlogin,
  GetDealerdata,
  Toast,
  fetchDataFromApi,
} from "../../Utils/general-function";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Header";
import Loader from "../../Utils/Loader";
import Search from "../../Search";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import CatlogueHeader from "../CatlogueHeader";
import { RWebShare } from "react-web-share";
import Innerlistcommon from "./Innerlistcommon";
import NoCars from "../../Utils/NoCars";
function Catlogue(props) {
  const [catloguelist, setCatloguelist] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [originalList, setOriginalList] = useState([]);
  const [apiData, setapiData] = useState({});
  let params = useParams();
  useEffect(() => {
    const fetchdata = async () => {
      if (!Userlogin()) {
        navigate("/Login");
        return;
      }
      try {
        GetDealerdata().then((res) => {
          setapiData(res);
        });
        const catloguedata = await fetchDataFromApi(
          "/api/CarCateloge/AllCatelogebycarnoDealerid?carno=0&carstatus=Catlogue"
        );
        if (
          catloguedata.Message ===
          "Authorization has been denied for this request."
        ) {
          localStorage.clear();
          navigate("/Login");
          return;
        }
        setCatloguelist(catloguedata.Data);
        setOriginalList(catloguedata.Data);
        setLoading(false);
      } catch (error) {
        console.log("error from catalogue", error);
      }
    };
    fetchdata();
  }, [navigate, params.id]);

  const handleCallback = (childData) => {
    setLoading(true);
    setCatloguelist(childData);
    setLoading(false);
  };

  const GenerateQr = async () => {
    setLoading(true);
    // debugger

    const min = 1;
    const max = 500;
    const rand = parseInt(min + Math.random() * (max - min));
    const canvas1 = await document.getElementById("print");
    canvas1.style.display = "block";
    try {
      html2canvas(canvas1).then((canvas) => {
        const pngUrl = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(pngUrl, "PNG", 12.3, 0, 185, 291, "", "SLOW");
        pdf.save("Catalogue_QR" + rand);
        setLoading(false);
        Toast(true, "Qr generated Successfully", "");
      });
    } catch (e) {
      Toast(false, "", e);
    }

    canvas1.style.display = "none";
  };
  return (
    <div>
      {/* <Helmet>
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Carthi78" />
        <meta property="og:title" content="Catlogue Car " />
        <meta property="og:description" content="My Portfolio" />
         
      </Helmet> */}
      {loading ? <Loader /> : null}
      <Header />
      <div className="text-end pt-3 pe-2">
        <button
          onClick={(e) => GenerateQr()}
          className="sharebtn sharbtnpos"
          style={{ padding: "0 0 0 8px" }}
        >
          QR Code
          <span style={{ marginLeft: "9px" }}>
            <img src="assets/images/qr-white.png" alt="" />
          </span>
        </button>
      </div>

      <CatlogueHeader Datafromparent={apiData} />

      <div className="page-space" style={{ marginBottom: "50px" }}>
        <div className="text-center pb-3">
          <Link to="/Specifiedcarcatlogue" className="attachbtn">Model Based Catalog</Link>
        </div>

        <Search dataFromParent={originalList} parentCallback={handleCallback} />
        {catloguelist.length === 0 ? (
          <NoCars/>
        ) : (<>
      <Innerlistcommon catloguelist={catloguelist} />
        {/* <ScrollButton/> */}
        {catloguelist.length === 0 ? null : (
          <RWebShare
            data={{
              text: "",
              url:"https://m.carthi.in/#/Catlogueshare/" +apiData.DealerRand +"/" +apiData.Firmname,
              title: apiData.Firmname,
            }}
            onClick={() => console.log("shared successfully!")}
          >
            <button className="sharebtn fixshare">
              Share catalogue
              <span>
                <img src="assets/images/share.png" alt="" />
              </span>
            </button>
          </RWebShare>

        )}
</>)}
        <div id="print" style={{ display: "none" }} className="div_print">
          <div className="qrdiv">
            <h2
              style={{
                fontSize: "30px",
                lineHeight: "40px",
                fontWeight: "600",
                marginBottom: "50px",
              }}
            >
              Scan QR
            </h2>
            <QRCode
              value={
                "https://m.carthi.in/#/Catlogueshare/" +
                apiData.DealerRand +
                "/" +
                apiData.Firmname
              }
              size={290}
              level={"H"}
              includeMargin={true}
            />
            {/* <h2
                    style={{
                      fontSize: "30px",
                      lineHeight: "40px",
                      fontWeight: "600",
                      marginTop: " 30px",
                    }}
                  >
                    {list.carnumber}
                  </h2> */}
            <div style={{ marginTop: "50px" }}>
              <h3
                style={{
                  fontSize: "24px",
                  lineHeight: "34px",
                  fontWeight: "500",
                }}
              >
                {apiData.Firmname}
              </h3>
              <h3
                style={{
                  fontSize: "24px",
                  lineHeight: "34px",
                  fontWeight: "500",
                }}
              >
                {apiData.Mobileno}
              </h3>
            </div>
            <img src="assets/images/scissors.png" className="scissors" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Catlogue;
