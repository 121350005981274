import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Userlogin, customStyles } from "./Utils/general-function";
import Header from "./Header";
import Loader from "./Utils/Loader";
import Select from "react-select";
import { useSearch } from "./Searchcontext";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import InfiniteScroll from "react-infinite-scroll-component";
import ScrollButton from "./Utils/scrollbutton";
import NoCars from "./Utils/NoCars";
// var count;
const SearchmoreCars = React.memo((props) => {
  const { search, setSearch, results, setResults, setCount, count } =
    useSearch();
  const [loading, setLoading] = useState(true);
  const [hasload, setHasload] = useState(true);
  const [Companylist, setCompanylist] = useState([]);
  const [Brandlist, setBrandlist] = useState([]);
  const [States, setStates] = useState([]);
  const [Cities, setCities] = useState([]);
  const [vehicletypes, setVehicletypes] = useState([]);
  const [errors, setErrors] = useState({});
  const [Fuelist, setFuelist] = useState([]);
  const [Gearlist, setGearlist] = useState([]);
  const navigate = useNavigate();
  const countRef = useRef(count); // set context value to local useref
  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      try {
        if (Userlogin()) {
          const companyData = fetch(
            process.env.REACT_APP_API_ENDPOINT + "/api/Common/companydata"
          ).then((response) => response.json());
          const fuelData = fetch(
            process.env.REACT_APP_API_ENDPOINT + "/api/Common/Fueldata"
          ).then((response) => response.json());
          const VehicleData = fetch(
            process.env.REACT_APP_API_ENDPOINT + "/api/Common/Vehiclenamedata"
          ).then((response) => response.json());
          const GearData = fetch(
            process.env.REACT_APP_API_ENDPOINT + "/api/Common/Geardata"
          ).then((response) => response.json());
          const [companyResponse, fuelResponse, vehicleresponse, Gearesponse] =
            await Promise.all([companyData, fuelData, VehicleData, GearData]);

          if (mounted) {
            setCompanylist(companyResponse.Data);
            setFuelist(fuelResponse.Data);
            setVehicletypes(vehicleresponse.Data);
            setGearlist(Gearesponse.Data);
          }

          if (search.company !== "") {
            const brandResponse = await fetch(
              process.env.REACT_APP_API_ENDPOINT +
                "/api/Common/activemodel/" +
                search.company.Value
            ).then((response) => response.json());

            if (mounted) {
              setBrandlist(brandResponse.Data || []);
            }
          }

          const statesResponse = await fetch(
            process.env.REACT_APP_API_ENDPOINT +
              "/api/Common/statenamedata?countryid=1"
          ).then((response) => response.json());

          if (mounted) {
            setStates(statesResponse.Data);
          }

          if (search.state) {
            fetchCitiesByState("1", search.state.Value);
          }

          setLoading(false);
        } else {
          navigate("/Login");
        }
      } catch (error) {
        console.error("Failed to fetch data", error);
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [navigate]);

  const handleCompanychange = (obj) => {
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/Common/activemodel/" +
        obj.Value
    )
      .then((response) => response.json())
      .then((res) => {
        setBrandlist(res.Data || []);
        setSearch((prevInput) => ({
          ...prevInput,
          company: obj,
          Model: "",
        }));
      });
  };
  const fetchCitiesByState = (country, state) => {
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/Common/citynamedata?countryid=" +
        country +
        "&stateid=" +
        state
    )
      .then((response) => response.json())
      .then((res) => {
        setCities(res.Data || []);
        setLoading(false);
      });
  };

  const handlestatechange = (obj) => {
    setLoading(true);
    setSearch((prevInput) => ({
      ...prevInput,
      state: obj,
      city: "",
    }));
    localStorage.setItem("state", JSON.stringify(obj));
    localStorage.removeItem("city");
    fetchCitiesByState("1", obj.Value);
  };

  const handleDropchange = (obj, name) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      [name]: obj,
    }));
  };
  const handlecitychange = (obj) => {
    setSearch((prevSearch) => ({
      ...prevSearch,
      city: obj,
    }));
    localStorage.setItem("city", JSON.stringify(obj));
  };
  const handleSubmit = () => {
    countRef.current = 1;
    fetchapi();
  };

  const fetchapi = () => {
    setHasload(true);
    const param = {
      nState: search.state?.Value,
      nCity: search.city?.Value || "",
      companyid: search.company?.Value || "",
      carmodelid: search.Model?.Value ? search.Model.Value : "",
      lowerprice: "",
      upperprice: "",
      kmbelow: "",      // search.KMange[0],
      kmupper:"",       //search.KMange[1],
      modelyearstart: search.ModelYear[0],
      modelyear: search.ModelYear[1],
      owner: "",
      fueltypeid: search.fuel?.Value ? search.fuel.Value : "",
      pageindex: countRef.current,
      vehicleid: search.vehicle?.Value || "",
      Gearid:search.Gear?.Value || ""
    };
    const formbody = new URLSearchParams(param).toString();
    setLoading(true);
    const requesheader = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formbody,
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/Carmaster/GetDetailsofcarsbySCCM_data",
      requesheader
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.Success) {
          // On Search data should not be concat
          if (countRef.current === 1) {
            setResults(res.Data);
          } else {
            setResults(results.concat(res.Data));
          }
          countRef.current++;
          setCount(countRef.current);
        } else {
          setHasload(false);
          if (countRef.current === 1) {
            setResults(res.Data);
          }
        }

        setLoading(false);
      });
  };

  const handleSlider = (values) => {
    setSearch((prevInput) => ({
      ...prevInput,
      ModelYear: values,
    }));
  };
  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}
      <div className="content">
        {/* <div className="text-center">
          <h2 className="page-title">Search Car</h2>
        </div> */}
        <div className="more-search">
          <div className="d-flex justify-content-between">
            <div className="search-div form-div w-48">
              <Select
                value={search.state} // take whole objects as value
                placeholder="Select state"
                name="state"
                options={States}
                getOptionLabel={(option) => option.Text}
                getOptionValue={(option) => option.Value}
                onChange={handlestatechange}
                closeMenuOnSelect={true}
                blurInputOnSelect={false}
                isSearchable={true}
                styles={customStyles}
              ></Select>

              <span className="text-danger">{errors.company}</span>
            </div>
            <div className="search-div form-div w-48">
              <Select
                placeholder="Select city"
                name="city"
                value={search.city}
                options={Cities}
                getOptionLabel={(option) => option.Text}
                getOptionValue={(option) => option.Text}
                onChange={handlecitychange}
                closeMenuOnSelect={true}
                blurInputOnSelect={false}
                isSearchable={true}
                styles={customStyles}
              ></Select>

              <span className="text-danger">{errors.company}</span>
            </div>
          </div>
          {/* <div className="d-flex justify-content-between"> */}

          <div className="form-div search-div">
            <Select
              placeholder="Select Vehicle Type"
              name="vehicle"
              value={search.vehicle}
              options={vehicletypes}
              getOptionLabel={(option) => option.Text}
              getOptionValue={(option) => option.Value}
              onChange={(obj) => handleDropchange(obj, "vehicle")}
              closeMenuOnSelect={true}
              blurInputOnSelect={false}
              isSearchable={true}
              styles={customStyles}
            ></Select>
            <span className="text-danger">{errors.Model}</span>
          </div>
          <div className="search-div form-div">
            <Select
              placeholder="Select Company"
              name="company"
              value={search.company}
              options={Companylist}
              getOptionLabel={(option) => option.Text}
              getOptionValue={(option) => option.Value}
              onChange={handleCompanychange}
              closeMenuOnSelect={true}
              blurInputOnSelect={false}
              isSearchable={true}
              styles={customStyles}
            ></Select>

            <span className="text-danger">{errors.company}</span>
          </div>

          <div className="search-div form-div">
            <Select
              placeholder="Select Model"
              name="Model"
              value={search.Model}
              options={Brandlist}
              getOptionLabel={(option) => option.Text}
              getOptionValue={(option) => option.Value}
              onChange={(obj) => handleDropchange(obj, "Model")}
              closeMenuOnSelect={true}
              blurInputOnSelect={false}
              isSearchable={true}
              isClearable={true}
              styles={customStyles}
            ></Select>
            <span className="text-danger">{errors.Model}</span>
          </div>
          <div className="form-div search-div">
            <Select
              placeholder="Select Fuel"
              name="fuel"
              value={search.fuel}
              options={Fuelist}
              getOptionLabel={(option) => option.Text}
              getOptionValue={(option) => option.Value}
              onChange={(obj) => handleDropchange(obj, "fuel")}
              closeMenuOnSelect={true}
              blurInputOnSelect={false}
              isSearchable={true}
              isClearable={true}
              styles={customStyles}
            ></Select>
            <span className="text-danger">{errors.Model}</span>
          </div>
          <div className="search-div form-div">
            <Select
              placeholder="Select Gear"
              name="Gear"
              value={search.Gear}
              options={Gearlist}
              getOptionLabel={(option) => option.Text}
              getOptionValue={(option) => option.Value}
              onChange={(obj) => handleDropchange(obj, "Gear")}
              closeMenuOnSelect={true}
              blurInputOnSelect={false}
              isSearchable={true}
              isClearable={true}
              styles={customStyles}
            ></Select>
            <span className="text-danger">{errors.Gear}</span>
          </div>

          {/* </div> */}

          <div className="form-div mdelyr-range">
            <div className="d-flex justify-content-between">
              <label className="form-label">Year</label>
              <label className="form-label">
                {search.ModelYear[0]}-{search.ModelYear[1]}
              </label>
            </div>
            <RangeSlider
              className="single-thumb"
              defaultValue={search.ModelYear}
              onInput={handleSlider}
              min={2003}
              max={new Date().getFullYear()}
              step={1}
            />
            <span className="text-danger">{errors.Model}</span>
          </div>
          <div className="text-center pt-3 mb-2">
            <button
              type="submit"
              className="submitbtn w-100"
              onClick={handleSubmit}
              style={{ backgroundColor: "#f60026" }}
            >
              Search Cars
            </button>
          </div>
        </div>

        <div style={{ marginBottom: "50px" }}>
          {results.length === 0 ? (
            <NoCars />
          ) : (
            <>
              {/* <div
            className="text-center pb-2"
            style={{ fontSize: "18px", fontWeight: "600", color: "#ff002a" }}
          >
            Total Cars : 50
          </div> */}
              {results.map((list) => (
                <div className="oldcatlg-div" key={list.carid}>
                  <div className="ct-dename">
                    {list.dealerprofilelist[0].Firmname}-{" "}
                    {list.dealerprofilelist[0].nCity}
                  </div>
                  <div className="oldcatlg-img">
                    <Link to={"/OtherCarDetails/" + list.Rand}>
                      <img src={list.carphoto} className="img-fluid" alt="" />
                    </Link>
                  </div>

                  <div className="oldcatlg-text">
                    <h2>
                      {list.modelyear} {list.carmodelid} {list.variantid}
                    </h2>
                    <div className="oldcatlg-number">
                      <span>{list.km} km</span>
                      <span> • {list.fueltypeid} </span>
                      <span> • {list.gear} </span>
                    </div>
                    <Link
                      to={"/OtherCarDetails/" + list.Rand}
                      className="mdbtn"
                    >
                      More Details
                    </Link>
                    <div></div>
                  </div>
                </div>
              ))}
            </>
          )}
          <InfiniteScroll
            dataLength={results.length}
            next={fetchapi}
            hasMore={hasload}
          ></InfiniteScroll>
          <ScrollButton />
        </div>
      </div>
    </div>
  );
});

export default SearchmoreCars;
