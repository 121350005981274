import React from 'react';

function RemainingPageTitle(props) {
   const data=props.dataFromParent
    return (
        <div>
           <h3>
                    {data?.modelyear} {data?.companyid} {data?.carmodelid}{" "}
                    {data?.variantid}{" "}
                    {data?.nSaleprice && (
                      <>
                      <span>•{data.gear}</span>
                        <span>•</span>
                        <span style={{ fontWeight: "500", color: "red" }}>
                          {" "}
                          ₹{Intl.NumberFormat("en-IN").format(data?.nSaleprice)}
                        </span>
                      </>
                    )}
                  </h3>  
        </div>
    );
}

export default RemainingPageTitle;