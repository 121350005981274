import React, { useEffect, useState } from "react";
import Header from "../Header";
import { Userlogin } from "../Utils/general-function";
import { useNavigate } from "react-router-dom";
import Loader from "../Utils/Loader";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toBlob } from "html-to-image";

function CheckRC(props) {
  const [loading, setLoading] = useState(false);
  const [searchtxt, setSearchtxt] = useState("");
  const [Details, setDetails] = useState({});
  const [Code, setCode] = useState();
  const [abortController, setAbortController] = useState(null);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [Member, setMember] = useState(null);
  const navigate = useNavigate();

  const OnChange = (e) => {
    setSearchtxt(e.target.value.toUpperCase());
  };

  const Status = {
    A: "Active",
    Y: "Active",
    D: "RC Suspended",
    C: "RC Cancel",
    S: "RC Surrender",
    T: "Scrap Vehicle",
    V: "Vehicle Scrapped RC Cancel",
    Z: "Vehicle Submitted For Scrapping",
    ACTIVE:"Active"
  };

  useEffect(() => {
    if (!Userlogin()) {
      navigate("/Login");
      return;
    }
    const header = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
      },
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/ActiveMemberCriteria/MemberActiveornotdata",
      header
    )
      .then((response) => response.json())
      .then((res) => {
        setMember(res);
      });
  }, []);

  const onSubmit = () => {
    setLoading(true);
    const div = document.getElementById("result");
    const btndiv = document.getElementById("btndiv");
    if (Member.Message === "Active") {
      fetchApi(div, btndiv);
    } else {
      setLoading(false);
      confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className="popup-overlay px-3 py-3 text-center">
              <div className="text-start">
                <p>
                  To access this service, we kindly request that you add{" "}
                  <b>{Member.Data.VehicleinPortfolio}</b> vehicles to your
                  portfolio.
                </p>
              </div>
              <button
                className="submitbtn me-2 popup-btn"
                onClick={() => {
                  onClose();
                }}
              >
                Ok
              </button>
            </div>
          );
        },
      });
    }
  };

  // const newapi=()=>{
  //   const options = {
  //     method: 'POST',
  //     headers: {
  //       accept: 'application/json',
  //       Token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0aW1lc3RhbXAiOjE2ODAwNjc3MjcsInBhcnRuZXJJZCI6IkNPUlAwMDAwMSIsInJlcWlkIjoia2V5NTg3MDQwIn0.uSFJwpuFC2a0vaybRHGZ2RI1C9fvzF2pqJ0Qr7qa1Nk',
  //       'Content-Type': 'application/json',
  //       authorisedkey: 'TVRJek5EVTJOelUwTnpKRFQxSlFNREF3TURFPQ=='
  //     },
  //     body: JSON.stringify({refid: searchtxt})
  //   };

  //   fetch('https://uat.paysprint.in/sprintverify-uat/api/v1/verification/rc_verify', options)
  //     .then(response => response.json())
  //     .then(response => console.log("newapi",response))
  //     .catch(err => console.error(err));
  // }

  const fetchApi = async (div, btndiv) => {
    if (isRequestPending) return;
    if (abortController) {
      abortController.abort(); // Cancel the previous request
    }

    const controller = new AbortController();
    setAbortController(controller);
    setIsRequestPending(true);
    const header = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
      },
      signal: controller.signal,
    };
    try {
      const response = await fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/RCHistory/GetRCHistory?Carno=" +
          searchtxt,
        header
      );
      const res = await response.json();

      div.style.display = "block";
      setDetails(JSON.parse(res.Data));
      if (res.Success === true) {
        setCode(200);
        btndiv.style.display = "flex";
      } else {
        const result = JSON.parse(res.Data);
        setCode(result.code);
      }
    } catch (error) {
        console.error("Fetch error:", error);
    
    } finally {
      setIsRequestPending(false);
      setLoading(false);
    }
  };

  function maskName(name) {
    let maskedName = "";
    for (let i = 0; i < name.length; i++) {
      if (i % 2 === 0) {
        maskedName += name[i];
      } else {
        maskedName += "*";
      }
    }
    return maskedName;
  }

  function replaceLastFiveCharacters(str) {
    return str?.length > 5 ? str.slice(0, -5) + "XXXXX" : "XXXXX";
  }
  const download = async () => {
    const div = document.getElementById("result");
    const options = {
      // pagebreak:{ mode: ['avoid-all', 'css', 'legacy'] },
      margin: [2, 10, 5, 0],
      filename: searchtxt + ".pdf",
      image: {
        type: "jpeg",
        quality: 1.0,
      },
      html2canvas: {
        scale: 2,
        dpi: 192,
        letterRendering: true,
        allowTaint: true,
      },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "portrait",
        compress: true,
      },
    };
    await html2pdf().from(div).set(options).toPdf().get("pdf").save();
  };

  const share = async () => {
    const div = document.getElementById("result");
    const scale = 3;
    const canvas = await html2canvas(div, { scale });
    const imgData = await canvas.toDataURL();
    const blob = await (await fetch(imgData)).blob();
    const filesArray = [
      new File([blob], searchtxt + ".png", {
        type: "image/png",
        lastModified: new Date().getTime(),
      }),
    ];
    if (navigator.canShare && navigator.canShare({ files: filesArray })) {
      navigator
        .share({
          files: filesArray,
        })

        .then(() => console.log("Share was successful."))

        .catch((error) => console.log("Sharing failed", error));
    } else {
      console.log(`Your system doesn't support sharing files.`);
    }
  };

  const heighlight = "#40bd40";
  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}
      <div className="text-center">
        <h2 className="page-title">Vehicle RC Check</h2>
      </div>
      <div className="page-space">
        <div className="input-group mt-2">
          <input
            type="text"
            className="form-control"
            placeholder="RC Number"
            name="searchtxt"
            value={searchtxt}
            onChange={OnChange}
          />
          <button
            className="btn rc-submit"
            type="submit"
            onClick={(e) => onSubmit()}
          >
            Submit
          </button>
        </div>
        <div
          className="justify-content-center"
          id="btndiv"
          style={{ display: "none" }}
        >
          <button className="cardoc-btn" onClick={(e) => download()}>
            <img
              src="assets/images/download-icon.svg"
              alt="Download"
              style={{ width: "32px" }}
            />
          </button>
          <button className="cardoc-btn">
            <img
              src="assets/images/share-d.png"
              alt="share"
              style={{ width: "32px" }}
              onClick={(e) => share()}
            />
          </button>
        </div>

        <div
          className="my-3 rc-details p-3"
          id="result"
          style={{ display: "none" }}
        >
          <div className="d-flex text-center justify-content-around flex-column">
            <span className="logodiv">
              <img src="assets/images/logo.png" alt="" />
            </span>
            <span
              className="title ps-4 "
              style={{ paddingTop: "2px", fontWeight: "700" }}
            >
              {" "}
              Vehicle Registration
            </span>
          </div>

          {Code === 200 ? (
            <div className="mt-4" id="cardetails">
              <div className="rc-div">
                <span className="rc-property">Vehicle Number </span>
                <span className="w-10">:</span>
                <span className="title ps-2 w-45" style={{ fontWeight: "700" }}>
                  {Details?.rc_number}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property" style={{ fontWeight: "700" }}>
                   Status
                </span>
                <span className="w-10">:</span>
                <span
                  className="title ps-2 w-45"
                  style={{
                    fontWeight: "500",
                    color: ["ACTIVE", "A", "Y"].includes(Details?.rc_status)
                      ? heighlight
                      : "red",
                  }}
                >
                  {Details && Status[Details.rc_status]}
                </span>
              </div>
              {/* <div className="rc-div">
                <span className="rc-property" style={{ fontWeight: "700" }}>
                  Blacklist Status
                </span>
                <span className="w-10">:</span>
                <span
                  className="title ps-2 w-45"
                  style={{ fontWeight: "500", color: "red" }}
                >
                  {Details?.blacklist_status ? Details?.blacklist_status : "-"}
                </span>
              </div> */}
              <div className="rc-div">
                <span className="rc-property">Financed</span>
                <span className="w-10">:</span>
                <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
                  {Details?.financed?.toString()
                    ? Details?.financed?.toString()
                    : "-"}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property" style={{ fontWeight: "700" }}>
                  Financer
                </span>
                <span className="w-10">:</span>
                <span
                  className="title ps-2 w-45"
                  style={{ fontWeight: "500", color: heighlight }}
                >
                  {Details?.financer ? Details?.financer : "-"}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">Insurance Upto</span>
                <span className="w-10">:</span>
                <span
                  className="title ps-2 w-45"
                  style={{ fontWeight: "500", color: heighlight }}
                >
                  {new Date(Details?.insurance_upto).toLocaleDateString(
                    "es-CL"
                  )}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">Insurance Company</span>
                <span className="w-10">:</span>
                <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
                  {Details?.insurance_company}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">Insurance Policy Number</span>
                <span className="w-10">:</span>
                <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
                  {Details?.insurance_policy_number
                    ? Details?.insurance_policy_number
                    : "Not available"}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">Maker Model</span>
                <span className="w-10">:</span>
                <span
                  className="title ps-2 w-45"
                  style={{ fontWeight: "500", color: heighlight }}
                >
                  {Details?.maker_model}
                </span>
              </div>
              {/* <div className="rc-div">
              <span className="rc-property">Vehicle Class</span>
              <span className="w-10">:</span>
              <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
               { Details?.vehicle_category}
              </span>
            </div>  */}
              <div className="rc-div">
                <span className="rc-property" style={{ fontWeight: "700" }}>
                  Owner Number
                </span>
                <span className="w-10">:</span>
                <span
                  className="title ps-2 w-45"
                  style={{ fontWeight: "500", color: heighlight }}
                >
                  {Details?.owner_number}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">Owner</span>
                <span className="w-10">:</span>
                <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
                  {Details?.owner_name ? maskName(Details.owner_name) : ""}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">Father Name</span>
                <span className="w-10">:</span>
                <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
                  {Details?.father_name ? maskName(Details.father_name) : ""}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">Chassis Number</span>
                <span className="w-10">:</span>
                <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
                  {(Details?.vehicle_chasi_number)}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">Engine Number</span>
                <span className="w-10">:</span>
                <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
                  {(Details?.vehicle_engine_number)}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">Fuel Type </span>
                <span className="w-10">:</span>
                <span
                  className="title ps-2 w-45"
                  style={{ fontWeight: "500", color: heighlight }}
                >
                  {Details?.fuel_type}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">Color</span>
                <span className="w-10">:</span>
                <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
                  {Details?.color}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">Emission Norm</span>
                <span className="w-10">:</span>
                <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
                  {Details?.norms_type}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property" style={{ fontWeight: "700" }}>
                  Manufactured on
                </span>
                <span className="w-10">:</span>
                <span
                  className="title ps-2 w-45"
                  style={{ fontWeight: "500", color: heighlight }}
                >
                  {Details?.manufacturing_date_formatted}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">Registered Date </span>
                <span className="w-10">:</span>
                <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
                  {new Date(Details?.registration_date).toLocaleDateString(
                    "es-CL"
                  )}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">Expiry Date</span>
                <span className="w-10">:</span>
                <span
                  className="title ps-2 w-45"
                  style={{ fontWeight: "500", color: heighlight }}
                >
                  {new Date(Details?.fit_up_to).toLocaleDateString("es-CL")}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">Tax Paid Upto</span>
                <span className="w-10">:</span>
                <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
                  {Details?.tax_paid_upto}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">RTO </span>
                <span className="w-10">:</span>
                <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
                  {Details?.registered_at}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">Cubic Capacity</span>
                <span className="w-10">:</span>
                <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
                  {Details?.cubic_capacity}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">Seating Capacity</span>
                <span className="w-10">:</span>
                <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
                  {Details?.seat_capacity ? Details?.seat_capacity : "-"}
                </span>
              </div>
              <div className="rc-div">
                <span className="rc-property">Noc Details</span>
                <span className="w-10">:</span>
                <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
                  {Details?.noc_details ? Details?.noc_details : "-"}
                </span>
              </div>
              {/* <div className="rc-div">
              <span className="rc-property">Masked </span><span className="w-10">:</span>
              <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
               { Details?.masked_name?.toString()}
              </span>
            </div>
            <div className="rc-div">
              <span className="rc-property">Maker Description</span><span className="w-10">:</span>
              <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
               { Details?.maker_description}
              </span>
            </div>
            <div className="rc-div">
              <span className="rc-property">Body Type :</span>
              <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
               { Details?.body_type}
              </span>
            </div> 
            <div className="rc-div">
              <span className="rc-property">Pollution Certificate Upto :</span>
              <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
               { Details?.pollutionCertificateUpto}
              </span>
            </div>
            <div className="rc-div">
              <span className="rc-property">Tax Upto :</span>
              <span className="title ps-2 w-45" style={{ fontWeight: "500" }}>
               { Details?.tax_upto}
              </span>
            </div>*/}
            </div>
          ) : (
            <div
              className="text-center mt-2"
              style={{ color: "#ff002a", fontWeight: "500" }}
            >
              <label>{Details.message} !!</label>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CheckRC;
