import React from "react";
import Header from "../Header";
import { Link } from "react-router-dom";

function Service(props) {
  return (
    <div>
      <Header />
      <div>
        <div className="dash-block m-2">
          <Link to="/Carstationary">
            <span>Stationery</span>
            <div className="d-flex justify-content-between align-items-center totalcar">
              <h3 style={{ fontSize: "14px", lineHeight: "14px" }}>
                Get readymade stationery
              </h3>
              <img src="assets/images/catalogue12.png" alt="" />
            </div>
          </Link>
        </div>
        <div className="dash-block m-2">
          <Link to="/Updatedcarprice">
            <span>New pricelist</span>
            <div className="d-flex justify-content-between align-items-center totalcar">
              <h3 style={{ fontSize: "14px", lineHeight: "14px" }}>
                Get updated pricelist
              </h3>
              <img src="assets/images/car-unsold12.png" alt="" />
            </div>
          </Link>
        </div>

        <div className="dash-block m-2">
          <Link to="/CheckRC">
            <span>RTO information</span>
            <div className="d-flex justify-content-between align-items-center totalcar">
              <h3 style={{ fontSize: "14px", lineHeight: "14px" }}>
                Get vehicle details by RC Number
              </h3>
              <img src="assets/images/information12.png" alt="" />
            </div>
          </Link>
        </div>
        
        <div className="dash-block m-2">
          <Link to="https://echallan.parivahan.gov.in/index/accused-challan#challan_list">
            <span>Check Challan</span>
            <div className="d-flex justify-content-between align-items-center totalcar">
              <h3 style={{ fontSize: "14px", lineHeight: "14px" }}>
              Check court challan only  
              </h3>
              <img src="assets/images/challan.png" alt="" />
            </div>
          </Link>
        </div>

        <div className="dash-block m-2">
          <Link to="/SearchmoreCars">
            <span>Dealer Market</span>
            <div className="d-flex justify-content-between align-items-center totalcar">
              <h3 style={{ fontSize: "14px", lineHeight: "14px" }}>
                Search vehicle from Dealers
              </h3>
              <img src="assets/images/trade12.png" alt="" />
            </div>
          </Link>
        </div>
        <div className="dash-block m-2">
          <Link to="/LoanEmi">
            <span>EMI Calculator</span>
            <div className="d-flex justify-content-between align-items-center totalcar">
              <h3 style={{ fontSize: "14px", lineHeight: "14px" }}>
                Calculate EMI for your vehicle
              </h3>
              <img src="assets/images/calculator12.png" alt="" />
            </div>
          </Link>
        </div>
        <div className="dash-block m-2">
          <Link to="/MessageTemplate">
            <span>Message Templates</span>
            <div className="d-flex justify-content-between align-items-center totalcar">
              <h3 style={{ fontSize: "14px", lineHeight: "14px" }}>
                Get readymade messages
              </h3>
              <img src="assets/images/template.png" alt="" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Service;
