// for sharing Detail page of catlogue car

import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "./Utils/Loader";
import Info from "./Utils/Info";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Footer from "./Utils/Footer";
import Header from "./Header";
import { Shareurl, Userlogin } from "./Utils/general-function";
function OtherCarDetails(props) {
  const [Catloguedetail, setCatloguedetail] = useState({ carnumber: "" });
  const [Photolist, setPhotolist] = useState([]);
  const [DealerData, setDealerData] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  let params = useParams();
  useEffect(() => {
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/Carmaster/GetdetailsofCarsbyRandomid?Randomno=" +
        params.carno
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.Success) {
          setCatloguedetail(res.Data);
          setPhotolist(res.Data.catlogephotolist);
          setDealerData(res.Data.dealerprofilelist[0]);
        }
      });
  }, [navigate, params.carno]);

  const handleload = () => {
    setLoading(false);
  };
  const Poster = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnHover: true,
    autoplaySpeed: 5000,
    swipeToSlide: true,
    cssEase: "linear",
  };
  return (
    <div className="mb-2 page-space" style={{marginTop:Userlogin()?"":"-66px"}}>
      {Userlogin()?<Header />:null}
      {loading ? <Loader /> : null}
      {Userlogin()?
      <div className="text-end pe-2">
        <button onClick={(e) => Shareurl(window.location.href)} className="sharebtn sharbtnpos">
          Share
          <span>
            <img src="assets/images/share.png" alt="" />
          </span>
        </button>
      </div>:null}
      <div>
        <div id="forshare">
          {/* <Titleinfo
            dataFromParent={{
              data: Catloguedetail,
              carno: "****" + Catloguedetail.carnumber.substring(6),
            }}
          /> */}
          <div>
            <h2 className="car-name" style={{ color: "red" }}>
              {Catloguedetail?.modelyear} {Catloguedetail?.companyid}{" "}
              {Catloguedetail?.carmodelid} {Catloguedetail?.variantid}
            </h2>
            <div className="car-number">
              <span>{Catloguedetail?.km} km</span>
              <span> • {Catloguedetail?.nCc} cc </span>
            </div>
          </div>
        </div>
        <div className="carslider npd2">
          <Slider {...Poster}>
            {Photolist.map((list) => (
              <div className="car-reldiv" key={list.id}>
                <TransformWrapper>
                  <TransformComponent>
                    <img
                      src={list.carphoto}
                      className="car-image"
                      alt=""
                      onLoad={handleload}
                    />
                  </TransformComponent>
                </TransformWrapper>
              </div>
            ))}
          </Slider>
        </div>
      </div>

      <Info dataFromParent={Catloguedetail} />
      <div className="mb-2">
        <Footer dataFromParent={DealerData} />
      </div>
      {/* <ScrollButton/> */}
    </div>
  );
}

export default OtherCarDetails;
