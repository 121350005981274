import React, { useState, useEffect } from 'react';

function ScrollButton() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      {showButton && (
        // <button onClick={scrollToTop} style={{ position: 'fixed', bottom: '20px', right: '20px' }}>
          <img src='assets/images/up-arrow.png' alt="" style={{ position: 'fixed', bottom: '4px', right: '4px' ,maxWidth:"50px" }} onClick={scrollToTop}/>
        // </button>
      )}
    </>
  );
}

export default ScrollButton;
