import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Toast } from "../Utils/general-function";

export default function NewPassword() {
  const [input, setInput] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const key = location.state?.key;

  const handleChange = (e) => {
    let value = e.target.value;
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: value,
    }));
  };

  const Validate = () => {
    let errors = {};
    let isValid = true;

    if (!input["New"]) {
      isValid = false;
      errors["New"] = "This feild is required";
    }
    if (!input["confirm"]) {
      isValid = false;
      errors["confirm"] = "This feild is required";
    }
    if (input["New"] !== input["confirm"]) {
      isValid = false;
      errors["confirm"] =
        "Your confirmation password doesn't match your new one.";
    }

    setErrors(errors);

    return isValid;
  };

  function ShowHidePassword() {
    var pwdtxt = document.getElementById("passwordtxt");
    var pwdicon = document.getElementById("passwordicon");
    if (pwdtxt.type === "password") {
      pwdtxt.type = "text";
      pwdicon.textContent = "visibility_off";
    } else {
      pwdtxt.type = "password";
      pwdicon.textContent = "visibility";
    }
  }

  const handleSubmit = () => {
    setLoading(true);
    if (Validate()) {
      var param = {
        Mobileno: key,
        password: input.confirm,
      };
      const formBody = Object.entries(param)
        .map(
          ([key, value]) =>
            encodeURIComponent(key) + "=" + encodeURIComponent(value)
        )
        .join("&");
      const requestOptions = {
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },
        body: formBody,
      };
      fetch(
        process.env.REACT_APP_API_ENDPOINT +"/api/ForgotPassword/UpdateforgotPassword",requestOptions)
        .then((response) => response.json())
        .then((res) => {
          setLoading(false);
          Toast(res.Success, res.Message, res.Message);
          if (res.Success) {
            localStorage.clear();
            navigate("/Login");
          }
        });
    }
  };

  return (
    <div>
        <div className="text-center pt-3 logo-div">
        <img src="assets/images/logo.png" className="logo-img" alt="" />
        <img src="assets/images/logo.png" className="logo-img logo-imgabs" alt=""
        />
      </div>
      <div
        className="content m-4 px-4 pb-4"
        style={{
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          borderRadius: "10px",
        }}
      >
        <div className="text-center">
          <h2 className="page-title">Generate Password</h2>
        </div>
        
          <div className="form-div">
            <label className="form-label">New Password</label>
            <input
              type="password"
              className="form-control"
              name="New"
              // value={input.name}
              onChange={handleChange}
            />
            <span className="text-danger">{errors.New}</span>
          </div>
          <div className="form-div">
          <label className="form-label">Confirm Password</label>
          <div style={{ position: "relative" }}>
          <input
            type="password"
            className="form-control"
            name="confirm"
            id="passwordtxt"
            // value={input.Mobileno}
            onChange={handleChange}
          />
          <span
            className="material-symbols-outlined eyespan"
            id="passwordicon"
            onClick={() => ShowHidePassword()}
            style={{top:"7px", right: "5px  "}}
          >
            visibility
          </span>
          </div>
          <label className="text-danger">{errors.confirm}</label>
        </div>

        <div className="text-center pt-3">
          <button type="submit" className="submitbtn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}
