import React,{useState} from 'react';
import Header from './Header';
import { useNavigate } from "react-router-dom";
import { Toast } from './Utils/general-function';
import Loader from "./Utils/Loader";


function Addlead(props) {
    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleChange = (e) => {
      const isCarNumber = e.target.name === "carno";
        let value = e.target.value;
        setInput((prevInput) => ({
          ...prevInput,
          [e.target.name]: isCarNumber ? value.toUpperCase() : value,
        }));
      };
      const Validate = () => {
        let errors = {};
        let isValid = true;
        if (!input["name"]) {
          isValid = false;
          errors["name"] = "This feild is required";
        }
        if (typeof input["name"] !== "undefined") {
          var re=new RegExp(/^[a-zA-Z ]*$/)
          if (!re.test(input["name"])) {
            isValid = false;
            errors["name"] = "Please enter only characters.";
          }
        }
        if (!input["Mobileno"]) {
          isValid = false;
          errors["Mobileno"] = "This feild is required";
        }
        if (typeof input["Mobileno"] !== "undefined") {
          var pattern = new RegExp(/^[0-9\b]+$/);
          if (!pattern.test(input["Mobileno"])) {
            isValid = false;
            errors["Mobileno"] = "Please enter only number.";
          } else if (input["Mobileno"].length !== 10) {
            isValid = false;
            errors["Mobileno"] = "Enter valid mobile number.";
          }
        }
        setErrors(errors);
    
        return isValid;
      };
      const handleSubmit = () => {
        if (Validate()) {
          setLoading(true)
          var param = {
            Name: input.name,
            Contactno: input.Mobileno,
            Carno:"",
            nDealerRandno: localStorage.getItem("Ca1itd"),
            nRemarks:input.remark?input.remark:""
          };
          const formBody = Object.entries(param).map(
              ([key, value]) => encodeURIComponent(key) + "=" + encodeURIComponent(value)).join("&");
          const requestOptions = {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
            },
            body: formBody,
          };
          fetch(
            process.env.REACT_APP_API_ENDPOINT + "/api/LeadQRCode/AddLeadQRCode",
            requestOptions
          ).then((response) => response.json())
          .then((res)=>{
            setLoading(false)
            if(res.Success){
              navigate("/Leaddata")
            }
           Toast(res.Success,res.Message,res.Message ) 


          })
        }
      };
    return (
        <div>
           <Header/>
           {loading?<Loader/>:null}
           <div className="content m-4 px-4 pb-4" style={{boxShadow: "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px", borderRadius: "10px"}}>
        <div className="text-center">
          <h2 className="page-title">Add Lead</h2>
        </div>
        <div className="form-div">
          <label className="form-label">Name</label>
          <input
            type="text"
            className="form-control"
            name="name"
            // value={input.name}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.name}</span>
        </div>

        <div className="form-div">
          <label className="form-label">Mobile No</label>
          <input
            type="text"
            className="form-control"
            name="Mobileno"
            // value={input.Mobileno}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.Mobileno}</span>
        </div>
        {/* <div className="form-div">
          <label className="form-label">Car No. <span
              style={{
                fontSize: "13px",
                color: "#686868",
                letterSpacing: "0.5px",
               }}>
              (Ex. GJ01HQ9999)
            </span></label>
          <input
            type="text"
            className="form-control"
            name="carno"
            value={input.carno}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.carno}</span>
        </div> */}
        <div className="form-div">
          <label className="form-label">Customer requirement</label>
          <input
            type="text"
            className="form-control"
            name="remark"
            // value={input.remark}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.remark}</span>
        </div>
        <div className="text-center pt-3">
          <button type="submit" className="submitbtn" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>

        </div>
    );
}

export default Addlead;



