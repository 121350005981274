import React from "react";
import Header from "./Header";
function Undermaintenance(props) {
  return (
    <div>
        <Header/>
      <div className="px-2 py-4 text-center">
        <img
          src="assets/images/maintenanace.jpg"
          alt=""
          style={{width:"100%"}}
        />
      </div>
    </div>
  );
}

export default Undermaintenance;
