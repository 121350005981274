import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Loader from "./Utils/Loader";
//USED FOR ONLY INDIVIDUAL CAR PHOTOS SHARING PAGE
const Individual_CarPhotoShare = (props) => {
    const [Photolist, setPhotolist] = useState([]);
    const [loading, setLoading] = useState(true);
    const params = useParams();

  useEffect(() => {
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/CarCateloge/catelogecardetailsbyrandomno?Randno=" +
        params.caRand +
        "&dealerid=" +
        params.dealerid
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.Success) {
          setPhotolist(res.Data.catlogephotolist);
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  return (
    <div className="px-2" style={{ marginTop: "-46px" }}>
     <div className="text-center">
          <h2 className="page-title">Vehicle Images</h2>
        </div>
      {loading ? <Loader /> : null}
      {Photolist.length < 1 ? (
        <h2 className="text-center">No Images Found</h2>
      ) : (
        <div className="pt-1">
          {Photolist.map((data) => (
            <div className="py-2">
              <img
                src={data.carphoto}
                className="rounded img-fluid"
                alt="somthing Wrong"
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Individual_CarPhotoShare;
