import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Loader from "./Utils/Loader";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { Toast, Userlogin, customStyles } from "./Utils/general-function";
import Select from "react-select";
function Stockrecord(props) {

  const dateFormat = "DD/MM/YYYY";
  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState({
    type: "Seller",
    modelid: "",
    startdate: dayjs().format(dateFormat),
  });
  const [errors, setErrors] = useState({});
  const [Carlist, setCarlist] = useState([]);
  const params = useParams();

  const navigate = useNavigate();
  const formRef = useRef(null);

 
  const header = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
    },
  };

  const handleChange = (e) => {
    const isCarNumber = e.target.name === "carno";
    let value = e.target.value;
    setInput((prevInput) => ({
      ...prevInput,
      [e.target.name]: isCarNumber ? value.toUpperCase() : value,
    }));
  };

  function onDatechange(value, name) {
    setInput((prevInput) => ({
      ...prevInput,
      [name]: value === null ? "" : value.format(dateFormat),
    }));
  }
  
  const Validate = () => {
    let errors = {};
    let isValid = true;

    if (!input["carno"]) {
      isValid = false;
      errors["carno"] = "This feild is required.";
    }
    if (!input["name"]) {
      isValid = false;
      errors["name"] = "This feild is required";
    }
    if (typeof input["name"] !== "undefined") {
      var re = new RegExp(/^[a-zA-Z ]*$/);
      if (!re.test(input["name"])) {
        isValid = false;
        errors["name"] = "Please enter only characters.";
      }
    }
    if (!input["Mobileno"]) {
      isValid = false;
      errors["Mobileno"] = "This feild is required";
    }
    if (typeof input["Mobileno"] !== "undefined") {
      var pattern = new RegExp(/^[0-9\b]+$/);
      if (!pattern.test(input["Mobileno"])) {
        isValid = false;
        errors["Mobileno"] = "Please enter only number.";
      } else if (input["Mobileno"].length !== 10) {
        isValid = false;
        errors["Mobileno"] = "Enter valid mobile number.";
      }
    }
    if (!input["startdate"]) {
      isValid = false;
      errors["startdate"] = "This feild  is required ";
    }

    setErrors(errors);

    return isValid;
  };
  const handleSubmit = (e) => {
    if (Validate()) {
      const API =
        params.name === "Add" ? "AddBuyerSeller" : "EditBuyerSellerdetails";
       setLoading(true);
      const Param = {
        Name: input.name,
        Mobileno: input.Mobileno,
        Carno: input.carno.Carno,
        carmodelid: input.modelid,
        date: input.startdate,
        Transaction: input.type,
        Rand: params.name,
      };
      // formRef.current.reset();

      const formbody = new URLSearchParams(Param).toString();

      const option = {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
          Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
        },
        body: formbody,
      };

      fetch(
        process.env.REACT_APP_API_ENDPOINT + "/api/BuyerSeller/" + API,
        option
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.Success) {
            params.name !== "Add"
              ? navigate("/SearchBuyerSeller")
              : setInput({
                  type: "Seller",
                  modelid: "",
                  startdate: dayjs().format(dateFormat),
                  name: "",
                  carno: "",
                  model: "",
                  Mobileno: "",
                });
          }
          Toast(res.Success, "Data Submitted Successfully", res.Message);
          setLoading(false);
        });
    } 
  };
  const handleDropchange = (obj, name) => {
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/BuyerSeller/Searchmodelbycarno?carno=" +
        obj.Carno,
      header
    )
      .then((response) => response.json())
      .then((res) => {

        setInput((prevSearch) => ({
          ...prevSearch,
          [name]: obj,
          modelid: res.Data.carmodelid,
          model: res.Data.carmodelname,
        }));
      });
  };
  useEffect(() => {
    if (!Userlogin()) {
      navigate("/login");
      return;
    }
    if (params.name !== "Add") {
      fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/BuyerSeller/EditBuyerSellerdetails?Rand=" +
          params.name,
        header
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.Success) {
            setInput({
              type: res.Data.Transaction,
              name: res.Data.Name,
              Mobileno: res.Data.Mobileno,
              carno: { Carno: res.Data.Carno },
              modelid: res.Data.carmodelid,
              model: res.Data.carmodelname,
              startdate: res.Data.date,
            });
          }
        });
    }
    fetchcarno(input.type);
    return () => {};
  }, [params.name]);

  const fetchcarno = (status) => {
    setLoading(true);
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/BuyerSeller/LoadCarnobyTransaction?Transaction=" +
        status,
      header
    )
      .then((response) => response.json())
      .then((res) => {
        setCarlist(res.Data);
        setLoading(false);
      });
  };

  return (
    <div>
      <Header />
      <Link
        to="/SearchBuyerSeller"
        className="sharebtn sharbtnpos"
        style={{ padding: "8px" }}
      >
        Update Entry
      </Link>
      {loading ? <Loader /> : null}
      <div className="content">
        <div className="text-center">
          <h2 className="page-title">Buyer/Seller Entry</h2>
        </div>
        {/* <form ref={formRef}> */}
          <div className="form-div">
            <label className="form-label">Type</label>
            <select
              className="form-select form-control"
              name="type"
              value={input.type}
              onChange={handleChange}
            >
              <option value="Seller">Seller </option>
              <option value="Buyer">Buyer </option>
            </select>

            <span className="text-danger">{errors.fuel}</span>
          </div>
          <div className="form-div">
            <label className="form-label">{` ${input.type} Name`}</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={input.name}
              onChange={handleChange}
            />
            <span className="text-danger">{errors.name}</span>
          </div>
          <div className="form-div">
            <label className="form-label">Mobile No</label>
            <input
              type="text"
              className="form-control"
              name="Mobileno"
              value={input.Mobileno}
              onChange={handleChange}
            />
            <span className="text-danger">{errors.Mobileno}</span>
          </div>
          <div className="form-div">
            <label className="form-label">Car No. </label>
            <Select
              name="carno"
              value={input.carno}
              options={Carlist}
              getOptionLabel={(option) => option.Carno}
              getOptionValue={(option) => option.Carno}
              onChange={(obj) => handleDropchange(obj, "carno")}
              closeMenuOnSelect={true}
              blurInputOnSelect={false}
              isSearchable={true}
              styles={customStyles}
            ></Select>
            <span className="text-danger">{errors.carno}</span>
          </div>
          <div className="form-div">
            <label className="form-label">Model</label>
            <input
              type="text"
              className="form-control"
              name="model"
              value={input.model}
              disabled
            />
          </div>
          <div className="form-div">
            <label className="form-label">Date</label>
            <DatePicker
              value={dayjs(input.startdate, dateFormat)}
              onChange={(e) => onDatechange(e, "startdate")}
              format={dateFormat}
              className="form-control"
              name="startdate"
              inputReadOnly={true}
              allowClear={false}
              maxDate={dayjs(
                new Date().toLocaleDateString("es-CL"),
                dateFormat
              )}
            />
            <span className="text-danger">{errors.startdate}</span>
          </div>
          <div className="text-center pt-3">
            <button type="submit" className="submitbtn" onClick={handleSubmit}>
              Submit
            </button>
          </div>
        {/* </form> */}
      </div>
    </div>
  );
}

export default Stockrecord;
