import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Loader from "./Utils/Loader";
import { Toast } from "./Utils/general-function";

const Document = React.memo((props) => {
  const [Carno, setCarno] = useState(props.dataFromParent.no);
  const [CarDOclist, setCarDOclist] = useState(props.dataFromParent.list);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCarno(props.dataFromParent.no);
    setCarDOclist(props.dataFromParent.list);
  }, [props.dataFromParent.list, props.dataFromParent.no]);

  function OnDownloadDoc(url, name) {
    setLoading(true);
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const a = document.createElement("a");
        a.href = url;
        a.download = name;
        a.style.display = "none";
        document.body.appendChild(a);
        a.click();
        // window.URL.revokeObjectURL(url);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
    // saveurl(url, name).then((res) => {
    //   setLoading(false);
    //   Toast(true,"Document Downloaded Successfully","")
    // });
  }
  const opendeletepopup = (random) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        let value = "";
        return (
          <div className="popup-overlay px-3 py-3 text-center">
            <label className="text-danger" style={{ fontWeight: "500" }}>
              To delete this record, we require your account password.
            </label>
            {/* <p>Are you sure you want to delete this record?</p> */}
            <div className="form-div">
              <label className="form-label">Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                onChange={(e) => {
                  value = e.target.value;
                  
                }}
              />
            </div>
            <button
              className="submitbtn me-2 popup-btn"
              onClick={() => {
                OnDeleteDoc(random, value);
                onClose();
              }}
            >
              Submit
            </button>
            <button
              className="submitbtn me-2 popup-btn"
              onClick={() => {
                onClose();
              }}
            >
              CLose
            </button>
          </div>
        );
      },
    });
  };
  const OnDeleteDoc = (random, password) => {
    const header = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
      },
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/cardocumentmaster/Deletedocument?Rand=" +random +
        "&password=" + encodeURIComponent(password), header)
      .then((response) => response.json())
      .then((res) => {
        if (res.Success) {
          let uppateDoclist = CarDOclist.filter((d) => d.Rand !== random);
          setCarDOclist(uppateDoclist);
        }
        Toast(res.Success, res.Message, res.Message);
      });
  };
  return (
    <div>
      {loading ? <Loader /> : null}
      <div className="pt-4">
        <h3 className="car-docstitle">
          {Carno} <span>Documents</span>
        </h3>
        {CarDOclist.length === 0 ? (
          <div>
            <h3 className="car-docstitle border-0">No Documents found!</h3>
          </div>
        ) : (
          CarDOclist.map((list) => (
            <div className="car-docsdiv" key={list.Rand}>
              <div className="d-flex align-items-center flex-nowrap">
                <img src="assets/images/car-doc.png" alt="" />
                <div className="flex-grow-1 px-3">
                  <h3>{list.documentid}</h3>
                </div>
                <div className="d-inline-flex flex-nowrap">
                  <button
                    className="cardoc-btn"
                    onClick={(e) =>
                      OnDownloadDoc(list.carphoto, list.Uploadfile)
                    }
                  >
                    <img
                      src="assets/images/download-icon.svg"
                      alt="Download"
                      style={{ width: "32px" }}
                    />
                  </button>
                  <button
                    className="cardoc-btn"
                    onClick={(e) => opendeletepopup(list.Rand)}
                  >
                    <img
                      src="assets/images/delete-car.png"
                      alt="Delete"
                      style={{ width: "32px" }}
                    />
                  </button>
                  {/* <button className="cardoc-btn">
                      <img
                        src="assets/images/share-d.png"
                        alt="share"
                        style={{ width: "32px" }}
                      />
                    </button> */}
                </div>
              </div>
              <span>{list.Uploadfile}</span>
            </div>
          ))
        )}
      </div>
    </div>
  );
});

export default Document;
