import React, { useEffect, useState } from "react";
import Header from "./Header";
import { useLocation, Link, useNavigate } from "react-router-dom";
import html2canvas from "html2canvas";
import {
  urltobase64,
  GetDealerdata,
  Userlogin,
} from "./Utils/general-function";
import Loader from "./Utils/Loader";
import Info from "./Utils/Info";
import Titleinfo from "./Utils/Titleinfo";
import Footer from "./Utils/Footer";

function Statuspage(props) {
  const location = useLocation();
  const details = location.state?.details;
  const [loading, setLoading] = useState(true);
  const [CatPhoto, setCatPhoto] = useState(details?.Cateloge);
  const [apiData, setapiData] = useState({});
  const navigate = useNavigate();
  async function Onshare() {
    const canvasElement = document.getElementById("forshare");
    let date = new Date();
    let ms = date.getMilliseconds();
    const name =
      details.modelyear +
      details.companyid +
      details.carmodelid +
      details.variantid +
      "_" +
      ms;
    const canvas = await html2canvas(canvasElement);
    const imgData = await canvas.toDataURL();
    const blob = await (await fetch(imgData)).blob();
    const filesArray = [
      new File([blob], name + ".png", {
        type: "image/png",
        lastModified: new Date().getTime(),
      }),
    ];
    if (navigator.canShare && navigator.canShare({ files: filesArray })) {
      navigator
        .share({
          files: filesArray,
        })

        .then(() => console.log("Share was successful."))

        .catch((error) => console.log("Sharing failed", error));
    } else {
      console.log(`Your system doesn't support sharing files.`);
    }
  }

  useEffect(() => {
    if (Userlogin()) {
      GetDealerdata().then((res) => {
        setapiData(res);
      });
      const Features=document.getElementById("features");
      if(Features){
              Features.style.display="none"
      }
      const Address=document.getElementById("footeraddress");
      if(Features){
        Address.style.display="none"
      }
      urltobase64(details.carphoto).then((res) => {
        setCatPhoto(res);
        setLoading(false);
      });
    } else {
      navigate("/Login");
    }
  }, [details?.carid, details?.carphoto, navigate]);

  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}
      <div className="text-end pt-3 pe-2">
        <button onClick={(e) => Onshare()} className="sharebtn sharbtnpos">
          Share
          <span>
            <img src="assets/images/share.png" alt="" />
          </span>
        </button>
      </div>
      <div className="page-space" style={{ marginTop: "-70px" }}>
        <div id="forshare" style={{ paddingTop: "60px" }}>
          <div className="statuspage">
            <Titleinfo
              dataFromParent={{
                data: details,
                carno: "****" + details?.carnumber.substring(6),
              }}
            />
          </div>

          <div className="position-relative">
          <img src="assets/images/abslogo.png" class="abs-logo" style={{right:0,left:10,maxWidth:"20%"}} alt="" />
            <img src={CatPhoto} className="car-imagess" alt="" />
            {details?.nCaravaibility === "Yes" ? (
              <span className="ribbon1">
                <span>Coming Soon</span>
              </span>
            ) : details?.CarStatus === "Sold" ? (
              <span className="ribbon1">
                <span>Sold Out</span>
              </span>
            ) : (
              ""
            )}
          </div>

          <div className="px-2 pb-2">
            <div className="statuspage">
              <Info dataFromParent={details} />
            </div>

            <Footer dataFromParent={apiData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Statuspage;
