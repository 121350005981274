import React, { useState, useEffect } from "react";
import { useNavigate, Link, useParams } from "react-router-dom";
import Loader from "../../Utils/Loader";
import Search from "../../Search";
import CatlogueHeader from "../CatlogueHeader";
import Commonsharelist from "./Commonsharelist";

function Specficatlogueshare(props) {
  const [catloguelist, setCatloguelist] = useState([]);
  const [DealerData, setDealerData] = useState({ Name: "", Mobileno: "" });
  const [originalList, setOriginalList] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const params = useParams();

  useEffect(() => {
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/BrandingProfile/GetBrandingProfile?Rand=" +
        params.id
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.Success === true) {
          setDealerData(res.Data);
        }
      });

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/Carmaster/GetdetailsofCarsbyRandomidModelid?Randomid=" +
        params.id +
        "&modelid=" +
        params.modelid
    )
      .then((response) => response.json())
      .then((res) => {
        setCatloguelist(res.Data || []);
        setOriginalList(res.Data || []);
        setLoading(false);
      });
  }, [navigate, params.id, params.modelid]);
  return (
    <div>
      {loading ? <Loader /> : null}
      <div style={{ marginTop: "-66px" }}>
        {params.Brand === "W_hB" ? (
          <CatlogueHeader Datafromparent={DealerData} />
        ) : null}

        <div className="page-space">
          {/* <h3 className="text-center" style={{ color: "#ff002a" }}>
            Total Cars : {originalList.length}
          </h3>
          <Search
            dataFromParent={originalList}
            parentCallback={handleCallback}
          /> */}
          <Commonsharelist
            catloguelist={catloguelist}
            DealerData={DealerData}
            Branding={params.Brand}
          />

        </div>
      </div>
    </div>
  );
}

export default Specficatlogueshare;
