import React from "react";
import Header from "./Header";

function Help(props) {
  return (
    <div>
      <Header />
      <div className="page-space">
        <div className="help-box">
          <div className="help-content">
            <div className="num">1</div>
            <div className="help-text">
              <h3>Complete your profile</h3>
              <label style={{ color: "#737373", fontSize: "15px" }}>
                Completing your profile will help in creating a cohesive and
                branded catalogue that effectively represents your business.
              </label>
            </div>
          </div>
        </div>

        <div className="rbrd-div"></div>

        <div className="help-box">
          <div className="help-content">
            <div className="num">2</div>
            <div className="help-text">
              <h3>Add Car</h3>
              <ol type="I">
                <li>Navigate to the menu and select the "Add Car" option.</li>
                <li>
                  After adding the car details, go to the "My Car" section.
                </li>
                <li>
                  Click the "Add to Catalogue" button. This will include the car
                  in your branded catalogue.
                </li>
                <li>
                  Click "More Details" to open the car's detailed page, then
                  click "Add Document" to upload car documents.
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div className="rbrd-div"></div>

        <div className="help-box">
          <div className="help-content">
            <div className="num">3</div>
            <div className="help-text">
              <h3>My Catalogue</h3>
              <ol type="I">
                <li>
                  Navigate to "My Catalogue" section to view the list of cars
                  you have added.
                </li>
                <li>
                  <span style={{ fontWeight: "600" }}>View Car Details:</span>
                  <ol type="a">
                    <li>
                      Each car in your catalogue will have a "More Details"
                      button.
                    </li>
                    <li>
                      Click on the "More Details" button to open the detailed
                      page for that specific car.
                    </li>
                  </ol>
                </li>
                <li>
                  <span style={{ fontWeight: "600" }}>Add More Photos:</span>
                  <ol type="a">
                    <li>
                      On the car details page, you will see an option to "Add
                      More Photos."
                    </li>
                    <li>
                      Click "Add More Photos" to upload multiple images of the
                      car from various angles.
                    </li>
                  </ol>
                </li>

                <li>
                  <span style={{ fontWeight: "600" }}>Share Car Details:</span>
                  <ol type="a">
                    <li>
                      On the car details page, you can share the car details and
                      photos in two ways.
                      <ol type="i">
                        <li>
                          <span style={{ fontWeight: "600" }}>
                            With Branding:
                          </span>
                          Share car details with your branding for a
                          professional look.
                        </li>
                        <li>
                          <span style={{ fontWeight: "600" }}>
                            Without Branding:
                          </span>
                          Share the car details without branding if preferred.
                        </li>
                      </ol>
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div className="rbrd-div"></div>
        <div className="help-box">
          <div className="help-content">
            <div className="num">4</div>
            <div className="help-text">
              <h3>Create Status</h3>
              <ol type="I">
                <li>
                  Navigate to "Create Status" section,then Choose the car for
                  which you want to create a status.
                </li>
                <li>
                  Your branding will be added automatically to the status to
                  maintain consistency and professionalism
                </li>
                <li>
                  Once the status is created, you have the option to share it
                  directly to social media platforms.
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div className="rbrd-div"></div>
        <div className="help-box">
          <div className="help-content">
            <div className="num">5</div>
            <div className="help-text">
              <h3>Generate QR</h3>
              <ol type="I">
                <li>
                  Select "Car QRs" from your menu, choose a car from your list,
                  and generate a QR code.
                </li>
                <li>
                  <span style={{ fontWeight: "600" }}>Use the QR Code:</span>
                  <ol type="a">
                    <li>
                      Print the generated QR code and place it on the respective
                      car.
                    </li>
                    <li>
                      When a customer visits your auto consultancy and scans the
                      QR code, they will be able to access the car's details
                      instantly.
                    </li>
                  </ol>
                </li>
                <li>
                  <span style={{ fontWeight: "600" }}>
                    Benefits of QR Code:
                  </span>
                  <ol type="a">
                    <li>
                      Customer Convenience: Customers can easily get detailed
                      information about the car by scanning the QR code,
                      enhancing their experience.
                    </li>
                    <li>
                      Lead Generation: By tracking who scans the QR code, the
                      system will provide valuable leads about potential
                      customers, accessible at "Leads" section.
                    </li>
                    <li>
                      Operational Efficiency: Reduces the need for extra
                      employees to explain car details to customers, as the QR
                      code provides all the necessary information.
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div className="rbrd-div"></div>
        <div className="help-box">
          <div className="help-content">
            <div className="num">6</div>
            <div className="help-text">
              <h3>Services</h3>
              <ol type="I">
                <li>
                  Navigate to the service section to access various services
                  such as:
                </li>
                <li>
                  <span style={{ fontWeight: "600" }}>Car Stationery:</span>
                  <ol type="a">
                    <li>
                      Get all readymade stationery, like purchase and sell
                      agreements, with your branding.
                    </li>
                  </ol>
                </li>
                <li>
                  <span style={{ fontWeight: "600" }}>New Car Price List:</span>
                  <ol type="a">
                    <li>Find the showroom price of any car.</li>
                  </ol>
                </li>
                <li>
                  <span style={{ fontWeight: "600" }}>
                    Car RTO Information:
                  </span>
                  <ol type="a">
                    <li>Obtain vehicle registration details by RC number</li>
                  </ol>
                </li>
              </ol>
            </div>
          </div>
        </div>

        <div className="rbrd-div"></div>
        <div className="help-box mb-2">
          <div className="help-content">
            <div className="num">7</div>
            <div className="help-text">
              <h3>Contact us</h3>
              <ol type="I">
                <li>
                  If you have any questions or encounter any issues, please
                  reach out to us through our support page by writing your
                  query.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Help;
