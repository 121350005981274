import React, { useState, useRef, useEffect } from "react";
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import "@tensorflow/tfjs";

const ObjectDetection = () => {
  const [imageURL, setImageURL] = useState(null);
  const [predictions, setPredictions] = useState([]);
  const imageRef = useRef();

  useEffect(() => {
    console.log("HIII");
    
    const detectObjects = async () => {
        
    //   if (imageRef.current) {
    //     const model = await cocoSsd.load();
    //     const predictions = await model.detect(imageRef.current);
    //     console.log(predictions,"j89y78y");
        
    //     setPredictions(predictions);
    //   }
    };

    detectObjects();
  }, [imageURL]);

  const handleImageUpload = async(e) => {
    const file = e.target.files[0];
    const imageUrl = URL.createObjectURL(file);
    setImageURL(imageUrl);
    const model = await cocoSsd.load();
    const predictions = await model.detect(imageRef.current);
    console.log(predictions,"j89y78y");
    
    setPredictions(predictions);
  };

  return (
    <div>
      <h1>Object Detection</h1>
      <input type="file" accept="image/*" onChange={handleImageUpload} />
      {imageURL && (
        <div>
          <img src={imageURL} alt="Uploaded" ref={imageRef} style={{width:"50%"}}onLoad={() => {}} />
          <div>
            {predictions.length > 0 && (
              <ul>
                {predictions.map((prediction, index) => (
                  <li key={index}>
                    {prediction.class}: {Math.round(prediction.score * 100)}%
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ObjectDetection;
