import React, { useState, useEffect } from "react";
import Header from "../Header";
import Loader from "../Utils/Loader";
import { Userlogin,saveurl } from "../Utils/general-function";
import { useNavigate } from "react-router-dom";

function Updatedcarprice(props) {
  const [loading, setLoading] = useState(true);
  const [Companylist, setCompanylist] = useState([]);
  const [Brandlist, setBrandlist] = useState([]);
  const [errors, setErrors] = useState({});
  const [input, setInput] = useState({});
  const [display,setDisplay]=useState("none");
  const [CarDOclist, setCarDOclist] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;
    if (Userlogin()) {
      fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Pricemaster/GetallCompanybypriceDetails")
        .then((response) => response.json())
        .then((res) => {
          if (mounted) {
            setCompanylist(res.Data||[]);
          }
        });
      
     
      setLoading(false);
    } else {
      navigate("/Login");
    }
    return () => {
      mounted = false;
    };
  }, [navigate]);
  const handleCompanychange = (e) => {
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/Common/activemodel/" +
        e.target.value
    )
      .then((response) => response.json())
      .then((res) => {
        setBrandlist(res.Data || []);
        setInput((prevInput) => ({
          ...prevInput,
          [e.target.name]: e.target.value}));
      });
  };

  const handleChange = (e) => {
    setInput((prevInput) => ({
        ...prevInput,
        [e.target.name]: e.target.value,
      }));
  };

  function handleSubmit(){
    setLoading(true)
    const header={
      headers:{
        Authorization:"Bearer " + localStorage.getItem("ca1rt6_t"),
      }
    }
    fetch(process.env.REACT_APP_API_ENDPOINT +"/api/Pricemaster/GetPricemasterDetailsbyid?compid="+input.company+"&modelid="+input.Model,header)
    .then((response)=>response.json())
    .then((res)=>{
      setCarDOclist(res.Data ||[])
      setDisplay("block")
      setLoading(false)
    })

  }

  function OnDownloadDoc(url,name){
    setLoading(true)
    saveurl(url,name).then((res)=>{
      setLoading(false)
    })
    
  }
  return(
    <div>
      <Header />
      {loading ? (<Loader />):null}
      <div className="content">
        <div className="text-center">
          <h2 className="page-title">Get Car Pricelist</h2>
        </div>

        <div className="form-div">
          <label className="form-label">Company</label>
          <select
            className="form-select form-control"
            name="company"
            defaultValue=""
            onChange={handleCompanychange}
          >
            <option value="" >
              --Select Company--
            </option>
            {Companylist.map((list) => (
              <option value={list.companyid} key={list.companyid}>
                {list.companyname}
              </option>
            ))}
          </select>
          <span className="text-danger">{errors.company}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Model</label>
          <select
            className="form-select form-control"
            name="Model"
            defaultValue=""
            onChange={handleChange}
          >
            <option value="">
              --Select Model--
            </option>
            {Brandlist.map((list) => (
              <option value={list.Value} key={list.Value}>
                {list.Text}
              </option>
            ))}
          </select>

          <span className="text-danger">{errors.Model}</span>
        </div>
        <div className="text-center pt-3">
          <button type="submit" className="submitbtn" onClick={handleSubmit}>
            Submit
          </button>

          
        <div className="pt-4"  style={{display:display}} >
          <h3 className="car-docstitle">
             <span>Documents</span>
          </h3>
          {CarDOclist.length === 0 ? (
            <div>
              <h3 className="car-docstitle border-0">No Documents found!</h3>
            </div>
          ) : (
            CarDOclist.map((list) => (
              <div className="car-docsdiv" key={list.id}>
                <div className="d-flex align-items-center flex-nowrap">
                  <img src="assets/images/car-doc.png" alt="" />
                  <div className="flex-grow-1 px-3">
                    <h3 style={{textAlign:'left' ,wordBreak: "break-word"}}>{list.companyname+"_"+list.modelname+"_"+list.nFile}</h3>
                  </div>
                  <div className="d-inline-flex flex-nowrap">
                    <button
                      className="cardoc-btn"
                      onClick={(e) =>
                        OnDownloadDoc(list.pricepdfPath, list.nFile)
                      }
                    >
                      <img
                        src="assets/images/download-icon.svg"
                        alt="Download"
                        style={{ width: "32px" }}
                      />
                    </button>
                    {/* <button className="cardoc-btn">
                      <img
                        src="assets/images/share-d.png"
                        alt="share"
                        style={{ width: "32px" }}
                      />
                    </button> */}
                  </div>
                </div>
                <span>{list.Uploadfile}</span>
              </div>
            ))
          )}
        </div>
        </div>
      </div>
    </div>
  );
}

export default Updatedcarprice;
