import React, { useEffect, useState } from "react";
import Header from "./Header";
import Loader from "./Utils/Loader";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import {
  Userlogin,
  generatePDFWithTable,
  Toast,
  fetchDataFromApi,
} from "./Utils/general-function";
import { useNavigate, Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

function Leaddata(props) {
  const [List, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedList, setSelectedList] = useState([]);
  const [searchtext, setSearchText] = useState("");
  const [originalList, setOriginalList] = useState([]);
  const [Manualdata, setManualdata] = useState([]);
  const navigate = useNavigate();
  const [input, setInput] = useState({
    startdate: new Date().toLocaleDateString("es-CL"),
  });
  const header = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
    },
  };
  const Onchange = (e) => {
    setSearchText(e.target.value);
  };
  useEffect(() => {
    const fetchlead = async () => {
      if (!Userlogin()) {
        navigate("/Login");
        return;
      }
      try {
        const Leaddata = await fetchDataFromApi(
          "/api/LeadQRCode/LeadQRCodeDetails"
        );
        if (
          Leaddata.Message === "Authorization has been denied for this request."
        ) {
          localStorage.clear();
          navigate("/Login");
          return;
        }
        setList(Leaddata.Data);
        setManualdata(Leaddata.Data.filter((d) => !d.Modelyear));
        setOriginalList(Leaddata.Data);
        setLoading(false);
      } catch (error) {
        console.log("Exception found", error);
      }
    };
    fetchlead();
  }, [navigate]);
  const dateFormat = "DD/MM/YYYY";
  // For Date Change Event
  function onDatechange(value, name) {
    setInput((prevInput) => ({
      ...prevInput,
      [name]: value === null ? "" : value.$d.toLocaleDateString("es-CL"),
    }));
  }
  //  For Filtering by Date
  const OnFilter = () => {
    setLoading(true);
    const header = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
      },
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/LeadQRCode/SearchLeadQRCode?date1=" +
        input.startdate +
        "&date2=" +
        input.enddate,
      header
    )
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
        setList(res.Data);
        setManualdata(res.Data.filter((d) => !d.Modelyear));
      });
  };
  const opendeletepopup = (random) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="popup-overlay px-3 py-3 text-center">
            <p>Are you sure you want to delete this record?</p>
            <button
              className="submitbtn me-2 popup-btn"
              onClick={() => {
                onDelete(random);
                onClose();
              }}
            >
              Yes
            </button>
            <button
              className="submitbtn me-2 popup-btn"
              onClick={() => {
                onClose();
              }}
            >
              No
            </button>
          </div>
        );
      },
    });
  };

  const onDelete = (rand) => {
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/LeadQRCode/Delete?Rand=" +
        rand,
      header
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.Success) {
          setList(List.filter((item) => item.Rand !== rand));
        }
      });
  };
  const oncheck = (e, data) => {
    if (e.target.checked) {
      setSelectedList([...selectedList, data]);
    } else {
      setSelectedList(selectedList.filter((item) => item !== data));
    }

    // setSelectedList(selected)
  };

  const onSelectall = (e, data) => {
    if (e.target.checked) {
      setSelectedList(data);
      var ele = document.getElementsByName("option1");
      for (var i = 0; i < ele.length; i++) {
        if (ele[i].type === "checkbox") ele[i].checked = true;
      }
    } else {
      setSelectedList([]);
      var el = document.getElementsByName("option1");
      for (var k = 0; k < el.length; k++) {
        if (el[k].type === "checkbox") el[k].checked = false;
      }
    }
    // setSelectedList(selected)
  };

  const onshowmanuallead = (e) => {
    if (e.target.checked) {
      setList(Manualdata);
    } else {
      setList(originalList);
    }
  };

  const OnSearch = () => {
    let filterdlist = originalList.filter(
      (d) =>
        d.Carno.toLowerCase().includes(searchtext.toLowerCase()) ||
        d.companyname.toLowerCase().includes(searchtext.toLowerCase()) ||
        d.carname.toLowerCase().includes(searchtext.toLowerCase())
    );

    setList(filterdlist);
  };

  const onEdit = (id, data) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        let value = data;

        return (
          <div className="popup-overlay px-3 py-3">
            <div className="form-div">
              <label className="form-label">Remark</label>
              <input
                type="text"
                className="form-control"
                name="remark"
                defaultValue={data}
                onChange={(e) => {
                  value = e.target.value;
                }}
              />
              {/* <span className="text-danger">{errors.name}</span> */}
            </div>
            <button
              className="submitbtn me-2 popup-btn"
              onClick={() => {
                Editremark(id, value);
                onClose();
              }}
            >
              Submit
            </button>
            {/* <button
              className="submitbtn me-2 popup-btn"
              onClick={() => {
                onClose();
              }}
            >
              No
            </button> */}
          </div>
        );
      },
    });
  };

  function Editremark(random, remark) {
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/LeadQRCode/editLeadQRCodeRemarks?leadrand=" +
        random +
        "&nRemarks=" +
        remark,
      header
    )
      .then((response) => response.json())
      .then((res) => {
        Toast(res.Success, res.Message, res.Message);
        window.location.reload();
      });
  }
  const onWhatsapp = (value) => {
    const iphone = window.navigator.userAgent.includes("Mac");
    if (iphone) {
      window.location.href = `https://wa.me/+91${value}`;
    } else {
      window.location.href = `intent://send?phone=${value}#Intent;scheme=whatsapp;package=com.whatsapp.w4b;end`;
    }
  };

  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}

      <div className="page-space">
        <div className="leadsearchbox">
          <div>
            <div className="car-searchbar">
              <input
                type="text"
                placeholder="Enter Vehicle No."
                onChange={Onchange}
              />
              <button onClick={() => OnSearch()}>
                <img src="assets/images/search-icon.png" alt="" />
              </button>
            </div>
          </div>
          <div
            className="car-searchbar d-flex"
            style={{ flexDirection: "column" }}
          >
            <div className="col-12">
              <div className="row g-2 ">
                <div className="col-6">
                  <label>From</label>
                  <DatePicker
                    onChange={(e) => onDatechange(e, "startdate")}
                    format={dateFormat}
                    className="w-100"
                    name="startdate"
                    inputReadOnly={true}
                    maxDate={dayjs(new Date().toLocaleDateString("es-CL"),dateFormat)}
                  />
                </div>
                <div className="col-6">
                  <label>To</label>
                  <DatePicker
                    onChange={(e) => onDatechange(e, "enddate")}
                    format={dateFormat}
                    className="w-100"
                    name="enddate"
                    inputReadOnly={true}
                    minDate={dayjs(input.startdate, dateFormat)}
                    maxDate={dayjs(new Date().toLocaleDateString("es-CL"),dateFormat)}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 text-center pt-3">
              <button
                type="submit"
                className="submitbtn"
                style={{
                  padding: "5px 18px 7px 18px",
                  fontSize: "14px",
                  backgroundColor: "red",
                }}
                onClick={(e) => OnFilter()}
              >
                Search
              </button>
            </div>
          </div>
        </div>
        {List.length !== 0 ? (
          <div className="col-12 text-end pb-3">
            <button
              type="submit"
              className="submitbtn"
              style={{ fontSize: "14px" }}
              onClick={(e) => generatePDFWithTable(selectedList)}
            >
              Download
            </button>
          </div>
        ) : (
          ""
        )}
        <div className="list-div">
          {List.length === 0 ? (
            <h3 className="text-center m-0 py-3" style={{ color: "#ff002a" }}>
              No Records Available
            </h3>
          ) : (
            <>
              <div className="d-flex justify-content-center">
                <div className="form-check-inline m-2 d-inline-flex">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    style={{ marginTop: "5px" }}
                    id="all"
                    name="option2"
                    onChange={(e) => onSelectall(e, List)}
                  />
                  <label
                    className="form-check-label ps-2"
                    style={{ fontSize: "14px" }}
                    htmlFor="all"
                  >
                    Select all for download
                  </label>
                </div>

                <div className="form-check-inline m-2 d-inline-flex">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    style={{ marginTop: "5px" }}
                    id="allManual"
                    name="option2"
                    onChange={(e) => onshowmanuallead(e)}
                  />
                  <label
                    className="form-check-label ps-2"
                    style={{ fontSize: "14px" }}
                    htmlFor="allManual"
                  >
                    Show all Lead manual
                  </label>
                </div>
              </div>

              {List.map((data, index) => (
                <div className="list-cardiv" key={index}>
                  <div className="d-flex justify-content-between">
                    <div className="form-check-inline">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        style={{ marginTop: "5px" }}
                        id={data.Rand}
                        name="option1"
                        onChange={(e) => oncheck(e, data)}
                      />
                      <label
                        className="form-check-label ps-2"
                        style={{ fontSize: "14px" }}
                        htmlFor={data.Rand}
                      >
                        {data.Modelyear ? (
                          <h3>
                            {data.Modelyear} {data.carname} {data.variantname}
                          </h3>
                        ) : (
                          <h3>Manual Lead</h3>
                        )}
                      </label>
                    </div>
                    <div className="d-flex" style={{ columnGap: "8px" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22"
                        height="100%"
                        viewBox="0 0 496 496"
                        onClick={() => onWhatsapp(data.Contactno)}
                      >
                        <g transform="matrix(0.9,0,0,0.9,24.799999999999955,24.799999999999955)">
                          <path
                            d="M321.824 276.528c-.88-1.424-3.168-2.304-6.608-4.016-3.456-1.68-20.48-10-23.632-11.152-1.376-.496-2.592-.896-3.712-.896-1.472 0-2.784.672-4.08 2.608-2.304 3.44-8.944 11.152-10.944 13.456-1.136 1.312-2.304 1.968-3.712 1.968-1.056 0-2.288-.368-3.76-1.12-3.488-1.68-14.624-5.328-27.824-16.992-10.272-9.104-17.216-20.336-19.248-23.776-2.016-3.408-.208-5.28 1.536-6.976 1.552-1.584 3.44-4.032 5.2-6.032 1.728-2 2.304-3.424 3.44-5.712 1.136-2.288.576-4.304-.304-6.016-.848-1.712-7.76-18.592-10.672-25.472-2.144-5.12-4.304-5.776-6.096-5.776-.608 0-1.184.08-1.696.08-1.984 0-4.272-.304-6.608-.304-2.304 0-6.032.864-9.216 4.32-3.152 3.408-12.112 11.712-12.112 28.592 0 16.896 12.384 33.184 14.144 35.472 1.712 2.304 23.92 38.064 59.056 51.808 20.368 7.968 28.944 9.776 33.776 9.776 3.52 0 5.056-.96 7.728-1.2 6.32-.56 20.448-8.32 23.344-16.32 2.88-8 2.88-14.896 2-16.32z"
                            fill="#14b617"
                            opacity="1"
                            data-original="#000000"
                          ></path>
                          <path
                            d="M362.944 0H133.056C60.928 0 0 60.944 0 133.056v229.872C0 435.056 60.928 496 133.056 496h229.888C435.056 496 496 435.056 496 362.912V133.056C496 60.944 435.072 0 362.944 0zM464 362.912c0 54.784-46.272 101.072-101.056 101.072H133.056C78.288 463.984 32 417.712 32 362.912V133.056C32 78.288 78.288 32 133.056 32h229.888C417.712 32 464 78.272 464 133.056v229.856z"
                            fill="#14b617"
                            opacity="1"
                            data-original="#000000"
                          ></path>
                          <path
                            d="M250.608 88.32c-86.592 0-157.04 69.968-157.04 155.984 0 25.936 6.448 51.36 18.736 73.968l-23.12 68.256a16.003 16.003 0 0 0 3.728 16.336c3.056 3.136 7.2 4.8 11.424 4.8 1.616 0 3.248-.24 4.848-.752l71.76-22.784c21.52 10.56 45.456 16.128 69.664 16.128 86.608 0 157.072-69.952 157.072-155.952 0-86.016-70.464-155.984-157.072-155.984zm-.032 279.952c-21.184 0-42.096-5.328-60.464-15.424a16.054 16.054 0 0 0-12.56-1.216L129.6 366.848l15.296-45.12c1.52-4.48.976-9.424-1.488-13.472-11.696-19.2-17.872-41.312-17.872-63.936 0-68.352 56.096-123.984 125.04-123.984 68.96 0 125.072 55.632 125.072 123.984 0 68.336-56.096 123.952-125.072 123.952z"
                            fill="#14b617"
                            opacity="1"
                            data-original="#000000"
                          ></path>
                        </g>
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22px"
                        height="100%"
                        viewBox="0 1 24 24"
                        onClick={() => onEdit(data.Rand, data.nRemarks)}
                      >
                        <g
                          fill="none"
                          stroke="#ff1237"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                        >
                          <path d="M9.533 11.15A1.823 1.823 0 0 0 9 12.438V15h2.578c.483 0 .947-.192 1.289-.534l7.6-7.604a1.822 1.822 0 0 0 0-2.577l-.751-.751a1.822 1.822 0 0 0-2.578 0z" />
                          <path d="M21 12c0 4.243 0 6.364-1.318 7.682C18.364 21 16.242 21 12 21c-4.243 0-6.364 0-7.682-1.318C3 18.364 3 16.242 3 12c0-4.243 0-6.364 1.318-7.682C5.636 3 7.758 3 12 3" />
                        </g>
                      </svg>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="22px"
                        height="100%"
                        viewBox="2 0 21 26"
                        onClick={() => opendeletepopup(data.Rand)}
                      >
                        <path
                          fill="#ff1237"
                          d="M6 19a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V7H6zM8 9h8v10H8zm7.5-5l-1-1h-5l-1 1H5v2h14V4z"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="list-grid">
                    <div className="list-details">
                      <div className="myflex">
                        <span>
                          <img src="assets/images/calender-svg.svg" alt="" />
                        </span>
                        <span className="title">{data.createdate}</span>
                      </div>
                    </div>
                    <div className="list-details">
                      <div className="myflex">
                        <span>
                          <img src="assets/images/profile-svg.svg" alt="" />
                        </span>
                        <span className="title">{data.Name}</span>
                      </div>
                    </div>
                    <div className="list-details">
                      <div className="myflex">
                        <span>
                          <img src="assets/images/car-num.svg" alt="" />
                        </span>
                        <span className="title">
                          {data.Carno && data.Carno !== "undefined"
                            ? data.Carno
                            : "----"}
                        </span>
                      </div>
                    </div>
                    <div className="list-details">
                      <div className="myflex">
                        <span>
                          <a
                            style={{ color: "#ff002a" }}
                            href={"tel:" + data.Contactno}
                          >
                            <img src="assets/images/call-svg.svg" alt="" />
                          </a>
                        </span>
                        <span className="title">
                          <a
                            style={{ color: "#ff002a" }}
                            href={"tel:" + data.Contactno}
                          >
                            {data.Contactno}
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                  {data.nRemarks && (
                    <div className="list-details mt-2">
                      <div className="myflex">
                        <span>
                          <img src="assets/images/info-svg.svg" alt="" />
                          {/* <svg width="32px" height="32px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill-rule="evenodd" clip-rule="evenodd" d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z" fill="#ff1237"></path> </g></svg> */}
                        </span>
                        <span className="title" style={{ fontWeight: "500" }}>
                          {data.nRemarks}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Leaddata;
