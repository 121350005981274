import React, { useState, useEffect } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import { Modal } from "react-bootstrap";
import $ from "jquery";
import Resizer from "react-image-file-resizer";
import { base64ToBlob, Toast } from "../Utils/general-function";

function Upload_photo(props) {
  const [src, setsrc] = useState();
  const [show, setshow] = useState();
  const [cropper, setCropper] = useState();
  const [name, setName] = useState("");
  const [error, setError] = useState("");
  const [cropResult, setcropResult] = useState("assets/images/upload-img.png");

  function Validate(target) {
    var Isvalid = true;

    if (target.files[0].size > 1024 * 1024 * 10) {
      Isvalid = false;
      setError("Sorry, you can upload photo up to 10 MB.");
    }
    const file = target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (!allowedTypes.includes(file.type)) {
      Isvalid = false;
      setError("only .jpg, .png and .jpeg extensions are allowed.");
    }

    return Isvalid;
  }

  useEffect(() => {
    setcropResult(
      props.dataFromParent
        ? props.dataFromParent.split(",")[0]
        : "assets/images/upload-img.png"
    );
  }, [props.dataFromParent]);
  function onChange(e) {
    //e.preventDefault();\
    const files = e.dataTransfer?.files || e.target?.files;
    if (files) {
      if (Validate(e.target)) {
        setError("");

        setName(files[0].name);
        const reader = new FileReader();
        reader.onload = async () => {
          // Image loaded, now perform object detection
          // const image = new Image();
          // image.onload = async () => {
          //   try {
          //     // Load the COCO-SSD model
          //     // eslint-disable-next-line no-undef
          //     const model = await cocoSsd.load();

          //     // Make predictions
          //     const predictions = await model.detect(image);
          //     console.log("predictions", predictions);

          //     // Check if a car is detected
          //     const containsCar = predictions.some(
          //       (prediction) => prediction.class === "car"
          //     );
          //      if(containsCar){
          //       setsrc(reader.result);
          //       setshow(true);
          //      }
          //      else{
          //       Toast(false,"","Please upload image of car")
          //       $('input[type="file"]').val("");
          //      }
          //     // Now you can use 'containsCar' as needed
          //   } catch (error) {
          //     console.error("Error during object detection:", error);
          //   }
          // };
          // image.src = reader.result;
          setsrc(reader.result);
          setshow(true);
        };
        reader.readAsDataURL(files[0]);
      }
    }
  }
  function handlemodal() {
    setshow(!show);

    $('input[type="file"]').val("");
  }
  function cropImage() {
    const fixedWidth = 800;
    if (typeof cropper.getCroppedCanvas() === "undefined") {
      return;
    }
    const croppedCanvas = cropper.getCroppedCanvas();
    const croppedWidth = croppedCanvas.width;
    const croppedHeight = croppedCanvas.height;
    const aspectRatio = croppedWidth / croppedHeight;
    const fixedHeight = fixedWidth / aspectRatio;

    setcropResult(croppedCanvas.toDataURL());
    const blob = base64ToBlob(croppedCanvas.toDataURL());
    Resizer.imageFileResizer(
      blob,
      fixedWidth,
      fixedHeight,
      "JPEG",
      75,
      0,
      (resizedFile) => {
        // Handle the resized image file
        const data = {
          url: resizedFile,
          name: name,
        };
        setsrc(resizedFile);
        // $('input[type="file"]').val("");

        props.parentCallback(data);
        // Perform further actions with the resized image
      },
      "base64",
      fixedWidth,
      fixedHeight
    );

    setshow(false);
  }
  return (
    <div>
      <div className="strip-div">
        <div className="col-12 text-center">
          {/* <label htmlFor="image"> */}
          <input
            type="file"
            name="image"
            id="image"
            accept=".jpg,.jpeg,.png"
            className="form-control d-none"
            onChange={onChange}
          />
          <label className="image-upload" htmlFor="image">
            Click here to Upload vehicle Image
          </label>
          {/* <img src={cropResult} className="child-image" alt="Anchoredge" /> */}
          {/* </label> */}
        </div>
        <div className="text-danger">{error}</div>
      </div>
      <Modal
        show={show}
        onHide={handlemodal}
        className="crp-mdl"
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Cropper
          style={{ width: "100%", height: "auto" }}
          initialAspectRatio={800 / 550}
          aspectRatio={800 / 550}
          preview=".img-preview"
          guides={false}
          background={false}
          movable={false}
          zoomable={true}
          src={src}
          cropBoxResizable={true}
          onInitialized={(instance) => {
            setCropper(instance);
          }}
          viewMode={1}
          dragMode="move"
          minCropBoxWidth={700}
          minCropBoxHeight={500}
          // autoCropArea={1}
        />
        <button
          onClick={cropImage}
          className="submitbtn mb-0"
          style={{ borderRadius: "0" }}
        >
          Crop Image
        </button>
      </Modal>
    </div>
  );
}

export default Upload_photo;
