import React, { useEffect, useState } from "react";
import Header from "../Header";
import { Userlogin } from "../Utils/general-function";
import { useNavigate } from "react-router-dom";
import Loader from "../Utils/Loader";
import html2pdf from "html2pdf.js";
import html2canvas from "html2canvas";

function Challan(props) {
  const [loading, setLoading] = useState(false);
  const [searchtxt, setSearchtxt] = useState("");
  const [Details, setDetails] = useState([
    {
      number: 1,
      challanNumber: "HR36010230421161155",
      offenseDetails: "49. Wrong parking",
      challanPlace: "ESZ",
      payment_url: "NA",
      image_url: "",
      challanDate: "21/04/2023 04:11:55 PM",
      state: "HR",
      rto: "Gurgaon",
      accusedName: "AJAY SETIA",
      accused_father_name: "VIJAY SETIA",
      amount: "500",
      challanStatus: "Cash",
      court_status:
        "Online payment service is not available for this State or RTO. Kindly Contact Gurgaon - ESZ for further process.",
    },
    {
      number: 2,
      challanNumber: "HR46416221226021155",
      offenseDetails: "35. Violation of road marking",
      challanPlace: "GCB",
      payment_url: "NA",
      image_url: "",
      challanDate: "10/12/2022 08:50:20 AM",
      state: "HR",
      rto: "Gurgaon",
      accusedName: "VIJAY KUMAR",
      accused_father_name: "NA",
      amount: "500",
      challanStatus: "Pending",
      court_status:
        "The challan has been sent to court sec-40 ps area on 2023-03-25. Please dispose the challan in court. httpsvcourts.gov.in",
    },
  ]);
  const navigate = useNavigate();

  const OnChange = (e) => {
    setSearchtxt(e.target.value.toUpperCase());
  };
  useEffect(() => {
    if (!Userlogin()) {
      navigate("/Login");
      return;
    }
  }, []);

  const onSubmit = () => {
    setLoading(true);
    const div = document.getElementById("result");
    const btndiv = document.getElementById("btndiv");
    const header = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
      },
    };
           div.style.display = "block";
            btndiv.style.display = "flex";
            setLoading(false);
    try {
      fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/Vehiclechallan/GetVehiclechallan?Carno=" +
          searchtxt,
        header
      )
        .then((response) => response.json())
        .then((res) => {
          console.log(res);
          if (res.Success === true) {
            div.style.display = "block";
            btndiv.style.display = "flex";
            setDetails(JSON.parse(res.Data.nJsonstring));
            setLoading(false);
          } else {
          }
        });
    } catch (error) {
      setLoading(false);
    }
  };

 
  const download = async () => {
    const div = document.getElementById("result");
    const options = {
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      margin: [2, 2, 5, 5],
      filename: searchtxt + ".pdf",
      image: {
        type: "jpeg",
        quality: 1.0,
      },
      html2canvas: {
        scale: 2,
        dpi: 192,
        letterRendering: true,
        allowTaint: true,
      },
      jsPDF: {
        unit: "mm",
        format: "a4",
        orientation: "portrait",
        compress: true,
      },
    };
    await html2pdf().from(div).set(options).toPdf().get("pdf").save();
  };

  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}
      <div className="text-center">
        <h2 className="page-title">Check Vehicle Challan</h2>
      </div>
      <div className="page-space">
        <div className="input-group mt-2">
          <input
            type="text"
            className="form-control"
            placeholder="RC Number"
            name="searchtxt"
            value={searchtxt}
            onChange={OnChange}
          />
          <button
            className="btn rc-submit"
            type="submit"
            onClick={(e) => onSubmit()}
          >
            Submit
          </button>
        </div>

        <div id="result" style={{ display:"block" }}>
          <div className="pt-2">
            <div className="d-flex text-center flex-column">
              <span>
                <img
                  src="assets/images/logo.png"
                  style={{ maxWidth: "120px" }}
                  alt=""
                />
              </span>
              <h4 className="ch-carno">{searchtxt}GJ18CG4554</h4>
            </div>
          </div>
          {Details.map((data) => (
            <div className="challan-div" key={data?.challanNumber}>
              <span>Challan No-{data?.challanNumber}</span>
              <div className="ch-details">
                <div>
                  <span>Challan Place</span> -{" "}
                  {data?.challanPlace ? data?.challanPlace : "NA"}
                </div>
                <div>
                  <span>RTO</span> - {data?.rto ? data?.rto : "NA"}
                </div>
                <div>
                  <span>State</span> - {data?.state ? data?.state : "NA"}
                </div>
                <div>
                  <span>Challan Date</span> -{" "}
                  {new Date(data?.challanDate).toLocaleDateString("es-CL")}
                </div>
                <div>
                  <span>Amount</span> - {data?.amount}
                </div>
                <div>
                  <span>Challan Status</span> - {data?.challanStatus}
                </div>
                <div>
                  <span>Accused Name</span> -{" "}
                  {data?.accusedName === "NA" ? "NA" : data.accusedName}
                </div>
                <div>
                  <span>Father Name</span> -{" "}
                  {data?.accused_father_name === "NA"
                    ? "NA"
                    : data.accused_father_name}
                </div>
                <div>
                  <span>Offense Detail</span> - {data?.offenseDetails}
                </div>
                <div>
                  <span>Court Status</span> - {data.court_status}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div
          className="justify-content-center mb-2"
          id="btndiv"
          style={{ display: "flex" }}
        >
          <button
            className="btn rc-submit"
            style={{ lineHeight: "18px" }}
            type="submit"
            onClick={(e) => download()}
          >
            Download Pdf
          </button>
          {/* <button className="cardoc-btn">
            <img
              src="assets/images/share-d.png"
              alt="share"
              style={{ width: "32px" }}
              onClick={(e) => share()}
            />
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default Challan;
