import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Loader from "./Utils/Loader";

function Payment(props) {
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const transactionid = searchParams.get("transactionid");

  useEffect(() => {
    // fetch();
  });

  return <div>
  <Loader/>
    <h4>{status}</h4>
  </div>;
}

export default Payment;
