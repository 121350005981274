import React from "react";

function NoCars({img="assets/images/no-car.png",msg="No vehicle available!"}) {
  return (
    <div className="px-2 py-4 text-center">
      <img src={img} alt="" className="nocar-img" />
      <h3 className="nocar-text">Sorry</h3>
      <p>{msg}</p>
    </div>
  );
}

export default NoCars;
