import React from "react";
import styled from "styled-components";

const Firstview = styled.div`
  border-radius: 6px;
  padding: 10px;
  font-size: 15px;
  line-height: 25px;
  margin-top: 15px;
  color: #444444;
`;
function Defaultpreview({img,msg}) {
  return (
    <Firstview>
      <div className="px-2 text-center">
        <img
          src={img}
          alt=""
          className="nocar-img"
          style={{ filter: "opacity(0.6)" }}
        />
        <p>
          {msg}
        </p>
      </div>
    </Firstview>
  );
}

export default Defaultpreview;
