import React, { useState, useEffect } from "react";
import {
  Userlogin,
  GetDealerdata,
  Toast,
  fetchDataFromApi,
} from "./Utils/general-function";
import { useNavigate, Link, useParams } from "react-router-dom";
import Header from "./Header";
import Loader from "./Utils/Loader";
import QRCode from "qrcode.react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Search from "./Search";
import html2pdf from "html2pdf.js";
import NoCars from "./Utils/NoCars";

function GenerateQr(props) {
  const [catloguelist, setCatloguelist] = useState([]);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  let params = useParams();
  const [apiData, setapiData] = useState({});
  const [originalList, setOriginalList] = useState([]);

  useEffect(() => {
    const fetchdata = async () => {
      if (!Userlogin()) {
        navigate("/Login");
        return;
      }
      try {
        GetDealerdata().then((res) => {
          setapiData(res);
        });
        const carlist = await fetchDataFromApi(
          "/api/CarCateloge/AllCatelogebycarnoDealerid?carno=0&carstatus=Catlogue"
        );
        if (
          carlist.Message === "Authorization has been denied for this request."
        ) {
          localStorage.clear();
          navigate("/Login");
          return;
        }
        setCatloguelist(carlist.Data);
        setOriginalList(carlist.Data);
        setLoading(false);
      } catch (error) {
        console.log("Exception Found:", error);
        setLoading(false);
      }
    };
    fetchdata();
  }, [navigate, params.id]);

  const downloadQR = async (name, id) => {
    setLoading(true);
    const min = 1;
    const max = 500;
    const rand = parseInt(min + Math.random() * (max - min));
    const canvas1 = await document.getElementById(id);
    canvas1.style.display = "block";
    try {
      html2canvas(canvas1).then((canvas) => {
        const pngUrl = canvas.toDataURL("image/png");
        const pdf = new jsPDF();
        pdf.addImage(pngUrl, "PNG", 12.3, 12.3, 185, 291, "", "SLOW");
        pdf.save(name + "QR" + rand);
        setLoading(false);
        Toast(true, "Qr generated Successfully", "");
      });
    } catch (e) {
      Toast(false, "", e);
    }

    canvas1.style.display = "none";
  };

  const downloadDetails = async (name, id) => {
    setLoading(true);
    const min = 1;
    const max = 500;
    const rand = parseInt(min + Math.random() * (max - min));
    const canvas1 = await document.getElementById(id);
    canvas1.style.display = "block";
    try {
      const options = {
        // pagebreak:{ mode: ['avoid-all', 'css', 'legacy'] },
        margin: [4, 2, 2, 0],
        filename: name + "Details" + rand + ".pdf",
        image: {
          type: "jpeg",
          quality: 1.0,
        },
        html2canvas: {
          scale: 2,
          dpi: 192,
          letterRendering: true,
          allowTaint: true,
        },
        jsPDF: {
          unit: "mm",
          format: "a4",
          orientation: "portrait",
          compress: true,
        },
      };
      await html2pdf().from(canvas1).set(options).toPdf().get("pdf").save();
      setLoading(false);
      Toast(true, "Qr generated Successfully", "");
    } catch (e) {
      Toast(false, "", e);
    }

    canvas1.style.display = "none";
  };
  const handleCallback = (childData) => {
    setLoading(true);
    setCatloguelist(childData);
    setLoading(false);
  };

  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}
      <div className="page-space">
        <Search dataFromParent={originalList} parentCallback={handleCallback} />

        {catloguelist.length === 0 ? (
         <NoCars/>
        ) : (
          catloguelist.map((list, index) => (
            <div key={list.carid}>
              <div className="oldcatlg-div">
                <div className="oldcatlg-img">
                  <Link to={"/Catlogue_Detail/" + list.Rand}>
                    <img src={list.carphoto} className="img-fluid" alt="" />
                  </Link>
                </div>

                <div className="oldcatlg-text">
                  <h2>
                    {list.modelyear} {list.companyid} {list.carmodelid}{" "}
                    {list.variantid}
                  </h2>
                  <div className="oldcatlg-number">
                    <span>{list.carnumber}</span>
                    <span> • {list.km} km</span>
                    <span> • {list.fueltypeid}</span>
                  </div>
                  <button
                    className="mdbtn border-0 me-2"
                    onClick={(e) => downloadQR(list.carnumber, list.Rand)}
                  >
                    Get QR
                  </button>
                  <button
                    className="mdbtn border-0"
                    onClick={(e) =>
                      downloadDetails(list.carnumber, list.Rand + "Detail")
                    }
                  >
                    Get Details
                  </button>
                  <div></div>
                </div>
              </div>

              <div
                id={list.Rand}
                style={{ display: "none" }}
                className="div_print"
              >
                <div className="text-center">
                  <span>
                    <img
                      src="assets/images/logo.png"
                      alt=""
                      style={{ width: "35%" }}
                    />
                  </span>
                </div>
                <div className="qrdiv">
                  <h2
                    style={{
                      fontSize: "30px",
                      lineHeight: "40px",
                      fontWeight: "600",
                      marginBottom: "50px",
                    }}
                  >
                    {list.modelyear} {list.companyid} {list.carmodelid}{" "}
                    {list.variantid}
                  </h2>
                  <QRCode
                    value={
                      "https://m.carthi.in/#/Leadform/" +
                      list.carnumber +
                      "/" +
                      list.Rand +
                      "/" +
                      apiData.DealerRand
                    }
                    size={290}
                    level={"H"}
                    includeMargin={true}
                  />
                  <h2
                    style={{
                      fontSize: "30px",
                      lineHeight: "40px",
                      fontWeight: "600",
                      marginTop: " 30px",
                    }}
                  >
                    {list.carnumber}
                  </h2>
                  <div style={{ marginTop: "50px" }}>
                    <h3
                      style={{
                        fontSize: "24px",
                        lineHeight: "34px",
                        fontWeight: "500",
                      }}
                    >
                      {apiData.Firmname}
                    </h3>
                    <h3
                      style={{
                        fontSize: "24px",
                        lineHeight: "34px",
                        fontWeight: "500",
                      }}
                    >
                       {apiData.Mobileno}{apiData.Mobileno1?" | "+apiData.Mobileno1.replace("/"," | "):" "}
                    </h3>
                  </div>
                  <img
                    src="assets/images/scissors.png"
                    className="scissors"
                    alt=""
                  />
                </div>
              </div>

              <div
                id={list.Rand + "Detail"}
                style={{ display: "none" }}
                className="div_print"
              >
                <div className="text-center">
                  <span>
                    <img
                      src="assets/images/logo.png"
                      alt=""
                      style={{ width: "35%" }}
                    />
                  </span>
                </div>
                <div className="qrdiv" style={{ width: "700px", top:"40%" }}>
                  <h2>
                    {list.modelyear} {list.companyid} {list.carmodelid}{" "}
                    {list.variantid}
                  </h2>
                  <div className="catlg-number">
                    <span style={{ fontWeight: "bold" }}>{list.carnumber}</span>
                    <span style={{ fontWeight: "bold" }}> • {list.km} km</span>
                    <span style={{ fontWeight: "bold" }}>
                      {" "}
                      • {list.fueltypeid}
                    </span>
                  </div>
                  <div className="car-grid">
                    <div className="car-details print-deail">
                      <div className="myflex">
                        <span>
                          <img src="assets/images/ownership.svg" alt="" />
                        </span>
                        <span className="title">Ownership</span>
                      </div>
                      <div className="info pt-detailval">
                        {list?.owner} Owner
                      </div>
                    </div>
                    <div className="car-details print-deail">
                      <div className="myflex">
                        <span>
                          <img src="assets/images/fuel.svg" alt="" />
                        </span>
                        <span className="title">Fuel Type</span>
                      </div>
                      <div className="info pt-detailval">
                        {list?.fueltypeid}
                      </div>
                    </div>
                    <div className="car-details print-deail">
                      <div className="myflex">
                        <span>
                          <img src="assets/images/color.svg" alt="" />
                        </span>
                        <span className="title">Color</span>
                      </div>
                      <div className="info pt-detailval">{list?.colour}</div>
                    </div>
                    <div className="car-details print-deail">
                      <div className="myflex">
                        <span>
                          <img src="assets/images/seats.svg" alt="" />
                        </span>
                        <span className="title">Seating Capacity</span>
                      </div>
                      <div className="info pt-detailval">
                        {list?.seatingcapicity}
                      </div>
                    </div>
                    <div className="car-details print-deail">
                      <div className="myflex">
                        <span>
                          <img src="assets/images/gear.png" alt="" />
                        </span>
                        <span className="title">Gear</span>
                      </div>
                      <div className="info pt-detailval">{list?.gear}</div>
                    </div>
                    <div className="car-details print-deail">
                      <div className="myflex">
                        <span>
                          <img src="assets/images/sunroof.png" alt="" />
                        </span>
                        <span className="title">Sunroof</span>
                      </div>
                      <div className="info pt-detailval">{list?.nSunRoof}</div>
                    </div>
                    <div className="car-details print-deail">
                      <div className="myflex">
                        <span>
                          <img src="assets/images/insurance.svg" alt="" />
                        </span>
                        <span className="title">Insurance</span>
                      </div>
                      <div className="info pt-detailval">
                        {list?.insurance === "Yes"
                          ? list?.insurance + "-" + list?.nInsurancetype
                          : list?.insurance}
                      </div>
                    </div>
                    {list?.insurance === "Yes" ? (
                      <>
                        <div className="car-details print-deail">
                          <div className="myflex">
                            <span>
                              <img
                                src="assets/images/registrationyear.svg"
                                alt=""
                              />
                            </span>
                            <span className="title">Insurance End Date</span>
                          </div>
                          <div className="info pt-detailval">
                            {new Date(
                              list?.insuranceendtdate
                            ).toLocaleDateString("es-CL")}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                  <div style={{ marginTop: "50px" }}>
                    <h3
                      style={{
                        fontSize: "24px",
                        lineHeight: "34px",
                        fontWeight: "500",
                      }}
                    >
                      {apiData.Firmname}
                    </h3>
                    <h3
                      style={{
                        fontSize: "24px",
                        lineHeight: "34px",
                        fontWeight: "500",
                      }}
                    >
                      {apiData.Mobileno}{apiData.Mobileno1?" | "+apiData.Mobileno1.replace("/"," | "):" "}
                    </h3>
                  </div>
                  <img
                    src="assets/images/scissors.png"
                    className="scissors"
                    alt=""
                  />
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default GenerateQr;
