import React, { useEffect } from "react";
import Header from "../Header";
import { Userlogin } from "../Utils/general-function";
import { useNavigate } from "react-router-dom";

function Challaninuse(props) {
  const navigate = useNavigate();
  useEffect(() => {
    if (!Userlogin()) {
        navigate("/login")
        return;
    }
    window.open(
      "https://echallan.parivahan.gov.in/index/accused-challan#challan_list",
      "_blank",
      
    );
  });
  
  
  return (
    <div>
        <Header/>
        <div>
  </div>
        {/* <div style={{ width: "100%", height: "100vh", overflow: "hidden" }}>
            <iframe
            src="https://echallan.parivahan.gov.in/index/accused-challan#challan_list"
            style={{ width: "100%", height: "100%", border: "none" }}
            title="External Website"
            />
        </div> */}
    </div>
  );
}

export default Challaninuse;
