import React from "react";
import { Modal, ModalHeader } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function Profilerequired(props) {
  return (
    <Modal
      show={props.open}
      onHide={(e) => props.closehandler()}
      id="resultpopup"
      className="modal"
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <ModalHeader closeButton>
     
      </ModalHeader> */}
      <Modal.Body>
        <div className="text-center px-4 pb-3">
        <h2 style={{ letterSpacing: "0.5px" }}>Complete your Profile</h2>
          <p
            style={{
              fontWeight: "500",
              fontSize: "14px",
              lineHeight: "20px",
            }}
          >
            Completing your profile will help in creating a cohesive and branded
            catalogue that effectively represents your business.Please complete
            your profile.
          </p>

          <div className="text-center mt-2">
            <Link
              to="/Profile"
              className="sharebtn"
              style={{ padding: "8px" }}
            >
              Update Profile
            </Link>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
