import React from 'react';

function CatlogueHeader(props) {
const DealerData=props.Datafromparent;
    return (
        <div>
           <div className="catlg-top">
        <h3>{DealerData.Firmname}</h3>
        <h4>{DealerData.Mobileno}{DealerData.Mobileno1?" | "+DealerData.Mobileno1.replace("/"," | "):" "}</h4>
        <a href={DealerData.nGpslink} style={{color:"white",textDecoration:"underline"}}>
        <div className="d-flex align-items-center justify-content-center">
                <img
                  src="assets/images/location.png"
                  style={{ width: "20px" }}
                  alt=""
                />
              
               <label className='ps-1'>{DealerData.Address}</label>
              </div>
        </a>
        
      </div>  
        </div>
    );
}

export default CatlogueHeader;