import React from 'react';

function Titleinfo(props) {
    const details=props.dataFromParent.data;
    const carno=props.dataFromParent.carno
    return (
        <div>
         <h2 className="car-name" style={{color:"red"}}>
            {details?.modelyear} {details?.companyid} {details?.carmodelid}{" "}
            {details?.variantid}
          </h2>
          <div className="car-number">
            {/* <span id="carno">{carno}</span> */}
            <span>{details?.km} km</span>
            <span> • {details?.nCc} cc </span>
            {details?.nSaleprice &&(<><span>•</span>
            <span style={{fontWeight:"500" ,color:"red"}}> ₹{Intl.NumberFormat("en-IN").format(details?.nSaleprice)}</span></>)}
          </div>
        </div>
    );
}

export default Titleinfo;