// for sharing Detail page of catlogue car

import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { Shareurl } from "../../Utils/general-function";
import { useNavigate, Link, useParams } from "react-router-dom";
import Loader from "../../Utils/Loader";
import Info from "../../Utils/Info";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import Titleinfo from "../../Utils/Titleinfo";
import Footer from "../../Utils/Footer";
import ScrollButton from "../../Utils/scrollbutton";
function CatDetails(props) {
  const [Catloguedetail, setCatloguedetail] = useState({ carnumber: "" });
  const [Photolist, setPhotolist] = useState([]);
  const [DealerData, setDealerData] = useState({});
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [Available, setAvailable] = useState(true);
  let params = useParams();
  useEffect(() => {
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/CarCateloge/catelogecardetailsbyrandomno?Randno=" +
        params.carno +
        "&dealerid=" +
        params.dealerid
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.Success) {
          setCatloguedetail(res.Data);
          setPhotolist(res.Data.catlogephotolist);
          setAvailable(true);
        } else {
          setCatloguedetail({});
          setAvailable(false);
          setLoading(false);
        }
      });

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/BrandingProfile/GetBrandingProfile?Rand=" +
        params.dealerid
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.Success === true) {
          setDealerData(res.Data);
        }
      });
  }, [navigate, params.carno, params.dealerid]);

  const handleload = () => {
    setLoading(false);
  };
  const Poster = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    pauseOnHover: true,
    autoplaySpeed: 5000,
    swipeToSlide: true,
    cssEase: "linear",
  };
  const isEmpty = (Catloguedetail) => JSON.stringify(Catloguedetail) === "{}";
  
  const othernumbers = DealerData.Mobileno1?.split("/");
  return (
    <div style={{ marginTop: "-66px" }} className="mb-2">
      {loading ? <Loader /> : null}
      {Available ? (
        <div>
          <div>
            <div id="forshare">
              <Titleinfo
                dataFromParent={{
                  data: Catloguedetail,
                  carno: "****" + Catloguedetail.carnumber.substring(6),
                }}
              />
            </div>
            <div className="carslider npd2">
              <Slider {...Poster}>
                {Photolist.map((list) => (
                  <div className="car-reldiv" key={list.id}>
                    <img
                      src="assets/images/abslogo.png"
                      className="abs-logo"
                      style={{ zIndex: "1" ,maxWidth: "15%"}}
                      alt=""
                    />
                    <TransformWrapper>
                      <TransformComponent>
                        <img
                          src={list.carphoto}
                          className="car-image"
                          alt=""
                          onLoad={handleload}
                        />
                      </TransformComponent>
                    </TransformWrapper>
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          <Info dataFromParent={Catloguedetail} />
          <div className={params.brandoption === "W_hB" ? "mb-2" : "d-none"}>
        <Footer dataFromParent={DealerData} />
      </div>
        </div>
      ) : (
        <div className="p-3">
          <div className="soldout-div">
            <img
              src="assets/images/sorry-soldout.png"
              alt=""
              className="soldimg"
            />
            <p className="soldmsg">
              Thank you for your interest! The car you're looking for has been
              sold. But don't worry, we have more great options for you. Please
              contact the dealer directly for assistance in finding a similar
              vehicle or to discuss any other inquiries. We're here to help!
            </p>
          </div>
          <div className="car-branding">
            <h4>Contact Dealer</h4>
            <div>
              <div className="car-branding-info">
                <img src="assets/images/user.png" alt="" />
                {DealerData.Firmname}
              </div>
              <div className="car-branding-info">
                <img src="assets/images/call.png" alt="" />
                <a href={"tel:" + DealerData.Mobileno}>{DealerData.Mobileno}</a>
                {DealerData.Mobileno1?
                othernumbers.map((data) => (
                <a href={"tel:" + data}> /{data}</a>)):""}
              </div>
              <div className="car-branding-info">
              <a href={DealerData.nGpslink} style={{ color: "black" }}>
                <img src="assets/images/address.png" alt="" />
                {DealerData.Address}</a>
              </div>
            </div>
          </div>
        </div>
      )}
     
      {/* <ScrollButton/> */}
    </div>
  );
}

export default CatDetails;
