/* eslint-disable no-undef */
import React, { useState, useEffect } from "react";
import {
  Userlogin,
  Toast,
  fetchDataFromApi,
  posttocarthipage,
} from "./Utils/general-function";
import { useNavigate, Link } from "react-router-dom";
import Header from "./Header";
import Loader from "./Utils/Loader";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import Search from "./Search";
import RemainingPageTitle from "./Utils/RemainingPageTitle";
import { toast } from "react-toastify";
import NoCars from "./Utils/NoCars";
import debounce from "lodash/debounce";


function MyCar(props) {
  const [Carlist, setCarlist] = useState([]);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const [SelectedButton, setSelectedButton] = useState("All");
  const navigate = useNavigate();
  const [originalList, setOriginalList] = useState([]);
  const [isRequestPending, setIsRequestPending] = useState(false);

  const header = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
    },
  };
  useEffect(() => {
    if (!Userlogin()) {
      navigate("/Login");
      return;
    }
    fetchData();
  }, [navigate]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const carData = await fetchDataFromApi("/api/Carmaster/Dealercar");
      if (
        carData.Message === "Authorization has been denied for this request."
      ) {
        localStorage.clear();
        navigate("/Login");
        return;
      }

      setCarlist(carData.Data);
      setOriginalList(carData.Data);
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };
  //------For Filtering car------//
  const toggleContent = (event) => {
    //const currentState = this.state.active;
    event.preventDefault();
    setLoading(true);
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/Carmaster/Searchcar?carstatus=" +
        event.currentTarget.textContent,
      header
    )
      .then((response) => response.json())
      .then((res) => {
        setCarlist(res.Data);
        setOriginalList(res.Data);
        setLoading(false);
      });
    setSelectedButton(event.currentTarget.textContent);
    setActive(!active);
  };

  const handleCallback = (childData) => {
    setLoading(true);
    // setSelectedButton("All");
    setActive(!active);
    setCarlist(childData);
    setLoading(false);
  };

  // first all popup to remove from catalogue.
  const Addtocatlogue = (data) => {
    if (data.CarStatus === "Sold") {
      toast.info("Sold vehicle can't be added to catalogue");
    } else {
      let Request;
      let Message;
      if (data.Cateloge === "No") {
        Message = "Vehicle Successfully added in Cateloge.";
        Request = "add";
        Addremovecatlogue(data.carnumber, data.carid, Request, Message, data.Rand);
        // if(localStorage.getItem("Permission")==="Allow"){
        //  posttocarthipage(data);
        // }
      } else {
        Message = "Vehicle Successfully removed from Cateloge.";
        Request = "remove";
        confirmAlert({
          customUI: ({ onClose }) => {
            window.addEventListener("popstate", onClose);
            return (
              <div className="popup-overlay px-3 py-3 text-center">
                <p>
                  Are you sure you want to remove this vehicle from catlogue?
                </p>
                <p
                  style={{ fontSize: "14px", color: "red", textAlign: "left" }}
                >
                  Note: Removing the vehicle from the catalogue will delete all
                  its uploaded photos.
                </p>
                <button
                  className="submitbtn me-2 popup-btn"
                  onClick={() => {
                    Addremovecatlogue(
                      data.carnumber,
                      data.carid,
                      Request,
                      Message,
                      data.Rand
                    );
                    onClose();
                  }}
                >
                  Yes
                </button>
                <button
                  className="submitbtn me-2 popup-btn"
                  onClick={() => {
                    onClose();
                  }}
                >
                  No
                </button>
              </div>
            );
          },
        });
      }
    }
  };

  const morephotospopup = (rand) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        window.addEventListener("popstate", onClose);
        return (
          <div
            className="popup-overlay px-3 py-3 text-center"
            style={{ width: "100%" }}
          >
            <p>
              🚗 Vehicle Added to Catalog! 🚗<br/> Click on the Add Photos button to upload more photos and complete your listing, or add them later in My Catalog.
            </p>
            <button
              className="submitbtn me-2 popup-btn"
              onClick={() => {
                navigate("/Catlogue_Detail/"+rand)
                onClose();
              }}
            >
              Add Photos
            </button>
            <button
              className="submitbtn me-2 popup-btn"
              onClick={() => {
                onClose();
              }}
            >
              Cancel
            </button>
          </div>
        );
      },
    });
  };

  const OnDelete = (random, password) => {
    const header = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
      },
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/Carmaster/Delete?Rand=" +
        random +
        "&password=" +
        encodeURIComponent(password),
      header
    )
      .then((response) => response.json())
      .then((res) => {
        if (res.Success) {
          let updatelist = Carlist.filter((d) => d.Rand !== random);
          setCarlist(updatelist);
        }
        Toast(res.Success, res.Message, res.Message);
      });
  };

  // for sold animation
  const callanimation = () => {
    const count = 300,
      defaults = {
        origin: { y: 0.7 },
      };

    const colors = ["#bb0000", "#ffffff", "#f3d573"];

    function fire(particleRatio, opts) {
      confetti(
        Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio) + 50,
          angle: 60,
          spread: 55,
          origin: { x: 0, y: 1 },
          colors: colors,
          startVelocity: 90,
          gravity: 0.5,
        })
      );
      confetti(
        Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio) + 50,
          angle: 120,
          spread: 55,
          origin: { x: 1, y: 1 },
          colors: colors,
          startVelocity: 90,
          gravity: 0.5,
        })
      );
    }

    fire(0.25, {
      spread: 26,
      startVelocity: 55,
    });

    fire(0.2, {
      spread: 60,
    });

    fire(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.8,
    });

    fire(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 1.2,
    });

    fire(0.1, {
      spread: 120,
      startVelocity: 45,
    });
  };
  const SoldUnsoldTOggle = (carno, status, carid) => {
    if (status === "UnSold") {
      callanimation();
    }
    const header = {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
        // "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    };

    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/Carmaster/changesoldunsoldstatus?carno=" +
        carno +
        "&carstatus=" +
        status,
      header
    )
      .then((response) => response.json())
      .then((res) => {
        // Toast(res.Success, Message, "Something went wrong");
        if (res.Success === true) {
          fetchData();
          //window.location.reload();
        } else {
          Toast(res.Success, "", "Something went wrong");
        }
      });
  };
  // Confimation Layer for Sold
  function GetConfirmation(carno, status, carid) {
    if (status === "UnSold") {
      confirmAlert({
        customUI: ({ onClose }) => {
          window.addEventListener("popstate", onClose);
          return (
            <div className="popup-overlay px-3 py-3 text-center">
              <p>Are you sure you want to Sold this vehicle?</p>
              <p style={{ fontSize: "14px", color: "red" }}>
                Note: Selling the vehicle deletes it from the catalogue along
                with all uploaded photos.
              </p>
              <button
                className="submitbtn me-2 popup-btn"
                onClick={() => {
                  SoldUnsoldTOggle(carno, status, carid);
                  onClose();
                }}
              >
                Yes
              </button>
              <button
                className="submitbtn me-2 popup-btn"
                onClick={() => {
                  onClose();
                }}
              >
                No
              </button>
            </div>
          );
        },
      });
    } else {
      SoldUnsoldTOggle(carno, status, carid);
    }
  }
  const opendeletepopup = (random) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        let value = "";
        window.addEventListener("popstate", onClose);
        return (
          <div className="popup-overlay px-3 py-3 text-center">
            {/* <p>Are you sure you want to delete this car record?</p> */}
            <label className="text-danger" style={{ fontWeight: "500" }}>
              To delete this record, we require your account password.
            </label>
            <div className="form-div">
              <label className="form-label">Password</label>
              <input
                type="password"
                className="form-control"
                name="password"
                onChange={(e) => {
                  value = e.target.value;
                }}
              />
            </div>
            <button
              className="submitbtn me-2 popup-btn"
              onClick={() => {
                OnDelete(random, value);
                onClose();
              }}
            >
              Submit
            </button>
            <button
              className="submitbtn me-2 popup-btn"
              onClick={() => {
                onClose();
              }}
            >
              Close
            </button>
          </div>
        );
      },
    });
  };

  const Addremovecatlogue = (carnumber, carid, Request, Message,rand) => {
    if (isRequestPending) return; // Prevent duplicate calls

    setIsRequestPending(true);

    const header = {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/Carmaster/addUpdatecateloge?carnumber=" +
        carnumber +
        "&carid=" +
        carid +
        "&carstatus=" +
        Request,
      header
    )
      .then((response) => response.json())
      .then((res) => {
        Toast(res.Success, Message, "Something went wrong");
        setIsRequestPending(false);
        if (res.Success === true) {
          const newData = Carlist.map((d) =>
            d.carid === carid
              ? { ...d, Cateloge: Request === "add" ? "Yes" : "No" }
              : d
          );
          setCarlist(newData);
          if (Request === "add") {
            morephotospopup(rand);
          }
          //fetchData();
        }
      });
  };

  function handleload() {
    setLoading(false);
  }
  // return loading ? (
  //   <Loader />
  // ) : (
  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}
      <div className="page-space">
        <Search dataFromParent={originalList} parentCallback={handleCallback} />

        <div className="sold-unsold">
          <button
            className={SelectedButton === "All" ? "active" : null}
            onClick={toggleContent}
          >
            All
          </button>{" "}
          <button
            className={SelectedButton === "Sold" ? "active" : null}
            onClick={toggleContent}
          >
            Sold
          </button>{" "}
          <button
            className={SelectedButton === "Unsold" ? "active" : null}
            onClick={toggleContent}
          >
            Unsold
          </button>
        </div>
        {Carlist.length === 0 ? (
          <NoCars />
        ) : (
          Carlist.map((data, i) => (
            <div className="car-div" key={data.Rand}>
              <div
                className={
                  data.CarStatus === "UnSold" ? "unsold-ribbon" : "sold-ribbon"
                }
              >
                <span>{data.CarStatus}</span>
              </div>
              <div className="car-radius">
                <img
                  src={data.carphoto}
                  className="w-100"
                  alt=""
                  onLoad={handleload}
                />
                <div className="car-smdetails">
                  <span className="abs-span">{data.fueltypeid}</span>
                  <RemainingPageTitle dataFromParent={data} />
                  <div className="car-km justify-content-between">
                    <div>
                      <img src="assets/images/car-num.svg" alt="" />
                      <span>{data.carnumber}</span>
                      <div className="clearfix"></div>
                      <img src="assets/images/car-km.svg" alt="" />
                      <span>{data.km}</span>
                      <div className="clearfix"></div>

                      <div
                        className="car-transition"
                        style={{
                          height: data.Carage ? "24px" : "0",
                          opacity: data.Carage ? "1" : "0",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21"
                          height="21"
                          viewBox="0 0 357.102 357.102"
                        >
                          <g>
                            <path
                              d="M192.349 13.8c-90.484 0-164.387 73.614-164.741 164.096l-.007 1.74c0 3.442-2.359 5.117-7.013 5.117H5.91c-2.831 0-4.688.974-5.517 2.756-1.241 2.666.784 5.613 1.616 6.64l39.939 63.027.243.331c1.074 1.224 2.489 1.929 3.882 1.929 1.134 0 2.782-.437 4.237-2.596l39.441-62.379c.918-1.075 3.102-4.123 1.872-6.864-.825-1.84-2.751-2.844-5.724-2.844H71.05c-4.432 0-6.998-1.062-6.998-6.853 0-70.426 57.554-127.686 128.297-127.686 70.749 0 128.308 57.574 128.308 128.321 0 70.748-57.559 128.314-128.308 128.314-18.913 0-37.12-4.01-54.114-11.928a18.059 18.059 0 0 0-7.684-1.707c-7.063 0-13.552 4.135-16.533 10.532-4.241 9.11-.283 19.972 8.823 24.214 21.852 10.18 45.237 15.342 69.508 15.342 90.845 0 164.753-73.907 164.753-164.751 0-90.844-73.908-164.751-164.753-164.751z"
                              fill="#ff002a"
                              opacity="1"
                              data-original="#000000"
                            ></path>
                            <path
                              d="M186.553 98.752c-8.284 0-15 6.716-15 15v63c0 7.883 4.452 16.048 11.081 20.319l54.797 35.291a14.924 14.924 0 0 0 8.108 2.392c4.929-.001 9.757-2.428 12.624-6.88 4.486-6.965 2.477-16.247-4.488-20.732l-52.122-33.569v-59.82c0-8.285-6.716-15.001-15-15.001z"
                              fill="#ff002a"
                              opacity="1"
                              data-original="#000000"
                            ></path>
                          </g>
                        </svg>
                        <span>{data.Carage} days </span>
                      </div>
                    </div>
                    <div className="d-inline-flex flex-column justify-content-start">
                      {data.nPurchaseprice && (
                        <span className="p-0">
                          Purchase Price : ₹{" "}
                          {Intl.NumberFormat("en-IN").format(
                            data.nPurchaseprice
                          )}
                        </span>
                      )}
                      <div className="d-flex align-items-center pt-2">
                        <div className="pe-2">
                          <button
                            className={
                              data.CarStatus === "UnSold"
                                ? "soldbtn subtn"
                                : "unsoldbtn subtn"
                            }
                            onClick={(e) =>
                              GetConfirmation(
                                data.carnumber,
                                data.CarStatus,
                                data.carid
                              )
                            }
                          >
                            {data.CarStatus === "UnSold" ? "Sold" : "Unsold"}
                          </button>
                          {/* <button className="unsoldbtn subtn">Unsold</button> */}
                        </div>

                        <Link to="/Editinquiry" state={{ random: data.Rand }}>
                          <img src="assets/images/edit-car.png" alt="" />
                        </Link>
                        <button
                          style={{ border: "none", background: "none" }}
                          onClick={(e) => opendeletepopup(data.Rand)}
                          // onClick={(e) => handleDownloadContact()}
                        >
                          <img src="assets/images/delete-car.png" alt="" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="two-btns">
                  <Link
                    to={"/CarDetail"}
                    state={{ details: data }}
                    className="red"
                  >
                    More Details
                  </Link>
                  <a
                    href={null}
                    className="black"
                    onClick={(e) => Addtocatlogue(data)}
                  >
                    {data.Cateloge === "No"
                      ? "Add To Catalogue"
                      : "Remove From Catalogue"}
                  </a>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default MyCar;
