import React, { useState, useEffect } from "react";
import Header from "../../Header";
import Slider from "react-slick";
import { Shareurl, Userlogin } from "../../Utils/general-function";
import { useNavigate, useParams } from "react-router-dom";
import Loader from "../../Utils/Loader";
import Resizer from "react-image-file-resizer";
import Info from "../../Utils/Info";
import Titleinfo from "../../Utils/Titleinfo";
import { Modal } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

function Catlogue_Detail(props) {
  const [Catloguedetail, setCatloguedetail] = useState({});
  const [Photolist, setPhotolist] = useState([]);
  const [show, setShow] = useState(false);
  const [Branding, setBranding] = useState("W_hB");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [maxerror ,setMaxerror]=useState("");
  let params = useParams();
  useEffect(() => {
    if (Userlogin() === true) {
      fetch(
        process.env.REACT_APP_API_ENDPOINT +
          "/api/CarCateloge/catelogecardetailsbyrandomno?Randno=" +
          params.carno+"&dealerid="+localStorage.getItem("Ca1itd")
      )
        .then((response) => response.json())
        .then((res) => {
          if (res.Success) {
            setCatloguedetail(res.Data);
            setPhotolist(res.Data.catlogephotolist);
          }
           setLoading(false)
        });
    } else {
      navigate("/login");
    }
  }, [navigate, params.carno]);

  const handleCallback = (childData) => {
    setLoading(true);
    // let photo = childData.url.split(",")[1];
    const param = {
      carid: Catloguedetail.carid,
      carnumber: Catloguedetail.carnumber,
      imageslist: JSON.stringify(childData),
    };
    var formBody = [];
    for (var property in param) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(param[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const requestOptions = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
      },
      body: formBody,
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT + "/api/CarCateloge/addcarcateloge",
      requestOptions
    )
      .then((response) => response.json())
      .then((res) => {
        setLoading(false);
         window.location.reload();
      });
  };
  const opendeletepopup = (random) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="popup-overlay px-3 py-3 text-center">
            <p>Are you sure you want to delete this photo?</p>
            <button
              className="submitbtn me-2 popup-btn"
              onClick={() => {
                OnDelete(random);
                onClose();
              }}
            >
              Yes
            </button>
            <button
              className="submitbtn me-2 popup-btn"
              onClick={() => {
                onClose();
              }}
            >
              No
            </button>
          </div>
        );
      },
    });
  };
  const OnDelete = (random) => {
    const header = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
      },
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/CarCateloge/DeleteCarCateloge?Rand=" +
        random,
      header
    )
      .then((response) => response.json())
      .then((res) => {
        let updatelist = Photolist.filter((d) => d.Rand !== random);
        setPhotolist(updatelist);
      });
  };
  //   /**-------------For Sending Sinngle image or file----------*/
  //   const handleFileChange = (e) => {
  //   const fileInput = e.target;
  //   const file = fileInput.files[0];

  //   // Check if a file is selected
  //   if (file) {
  //     // Set the desired width for the resized image
  //     const fixedWidth = 700;

  //     // Use the FileReader API to read the file and get its dimensions
  //     const reader = new FileReader();
  //     reader.onload = (e) => {
  //       const img = new Image();
  //       img.onload = () => {
  //         // Calculate the height based on the original aspect ratio
  //         const aspectRatio = img.width / img.height;
  //         const fixedHeight = fixedWidth / aspectRatio;

  //         //Resize the image
  //         Resizer.imageFileResizer(
  //           file,
  //           fixedWidth,
  //           fixedHeight,
  //           'JPEG',// Output format (JPEG, PNG, etc.)
  //           75,// Image quality (0-100)
  //           0,// Rotation
  //           (resizedFile) => {
  //             // Handle the resized image file
  //             console.log('Resized File:', resizedFile);
  //                const data={
  //                 url:resizedFile,
  //                 name:file.name
  //                }
  //                 handleCallback(data)
  //             // Perform further actions with the resized image
  //           },
  //           'base64',// Type of output (file or base64)
  //           fixedWidth,// Width for base64 type
  //           fixedHeight// Height for base64 type
  //         );
  //       };
  //       img.src = e.target.result;
  //       console.log(e.target.result,"ghhhh");
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  /**-----------For sending Multiple Files------------------*/
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files); // Convert FileList to array
    if (files.length+Photolist.length>20){
      20-Photolist.length===0?setMaxerror("20-photo limit; unable to upload more."):
    setMaxerror(`You can upload up to ${20-Photolist.length} photos.The max limit is 20`);
    return
    }
    setMaxerror("")
    Promise.all(
      files.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.onload = (event) => {
            const img = new Image();
            img.onload = () => {
              const aspectRatio = img.width / img.height;
              const fixedWidth = 700;
              const fixedHeight = fixedWidth / aspectRatio;

              Resizer.imageFileResizer(
                file,
                fixedWidth,
                fixedHeight,
                "JPEG",
                75,
                0,
                (resizedFile) => {
                  resolve({ url: resizedFile, name: file.name });
                },
                "base64",
                fixedWidth,
                fixedHeight
              );
            };
            img.src = event.target.result;
          };
          reader.readAsDataURL(file);
        });
      })
    ).then((resizedImages) => {
      handleCallback(resizedImages);
    });
  };

  function handlemodal() {
    setShow(!show);
    setBranding("W_hB") //reseting defalult share option on closing modal

  }
  const Onchange = (e) => {
    setBranding(e.target.value);
  };
  function handleload() {
    setLoading(false);
  }
  const Poster = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
    autoplaySpeed: 5000,
    swipeToSlide: true,
    cssEase: "linear",
  };
  function onshare() {
    setShow(true);
  }
  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}
      <div className="text-end pt-3 pe-2">
        <button onClick={(e) => onshare()} className="sharebtn sharbtnpos">
          Share
          <span>
            <img src="assets/images/share.png" alt="" />
          </span>
        </button>
      </div>
      <div className="page-space">
        <div>
          <Titleinfo
            dataFromParent={{
              data: Catloguedetail,
              carno: Catloguedetail.carnumber,
            }}
          />
        </div>
        <div className="carslider npd2">
          <Slider {...Poster}>
            {Photolist.map((list) => (
              <div className="car-reldiv" key={list.carphoto}>
                <img
                  src={list.carphoto}
                  className="car-image"
                  alt=""
                  onLoad={handleload}
                />
              </div>
            ))}
          </Slider>
        </div>
        <div className="text-center py-3">
          {/* <label htmlFor="image"> */}
          <input
            type="file"
            name="image"
            id="image"
            accept=".jpg,.jpeg,.png"
            className="form-control d-none"
            onChange={handleFileChange}
            multiple
          />
          <label className="catlg-addimg" htmlFor="image">
            <span className="mticon">
              <span className="material-symbols-outlined">add_a_photo</span>
            </span>{" "}
            Add More Images
          </label>
          <div className="text-danger">{maxerror}</div>
            
          {/* <CatimgUpload parentCallback={(data) => handleCallback(data)} /> */}
        </div>

        <div className="carimglist">
         
          {Photolist.map((list, i) => (
         
            <div className="listimg" key={list.carphoto}>
              <div className="all-listimg">
                <img src={list.carphoto} alt="" />
                {i === 0 ? null : (
                  <button onClick={(e) => opendeletepopup(list.Rand)}>
                    <img
                      src="assets/images/car-delete.png"
                      className="img-fluid"
                      alt=""
                    />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
        <Info dataFromParent={Catloguedetail} />
      </div>

      <Modal
        show={show}
        onHide={(e) => handlemodal()}
        id="resultpopup"
        className="modal"
      >
        <Modal.Header closeButton>
          <h4 className="modal-title">Choose Share with</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div class="form-div">
              <div>
                <div class="form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="radio1"
                    name="optradio"
                    value="W_hB"
                    onChange={Onchange}
                    defaultChecked
                  />
                  <label class="form-check-label px-1" for="radio1">
                    With Branding
                  </label>
                </div>
                <div class="form-check-inline">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="radio2"
                    name="optradio"
                    value="Wo_hB"
                    onChange={Onchange}
                  />
                  <label class="form-check-label px-1" for="radio2">
                    Without Branding
                  </label>
                </div>
                 {/* only indiviual car photo share option */}
                 <div class="form-check-inline mt-2">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="radio3"
                    name="optradio"
                    value="onlyPhoto"
                    onChange={Onchange}
                  />
                  <label class="form-check-label px-1" for="radio3">
                  Without detail (only photo)
                  </label>
                </div>
                <div className="text-center mt-4">
                  <input
                    type="submit"
                    value="Share"
                    className="attachbtn"
                    onClick={(e) =>{
                      Branding ==="onlyPhoto" ? 
                      Shareurl(`/#/IndividualCarPhotoShare/${params.carno}/${localStorage.getItem("Ca1itd")}`) :
                      Shareurl(
                        "/#/CatDetails/" +
                          params.carno +
                          "/" +
                          localStorage.getItem("Ca1itd")
                          +"/"+Branding

                      )
                      handlemodal()
                    }
                    }
                  />
                </div>
{/* end */}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Catlogue_Detail;
