import React, { useState } from "react";

function Search(props) {
  const [searchtext, setSearchText] = useState("");
  const originalList = props.dataFromParent;

  // const Onchange = (e) => {
  //   setSearchText(e.target.value);
  // };

  const OnSearch = (e) => {
    const searchtextt = e.target.value;
    setSearchText(e.target.value);
    let filterdlist = originalList.filter(
      (d) =>
        d.carnumber.toLowerCase().includes(searchtextt.toLowerCase()) ||
        d.companyid.toLowerCase().includes(searchtextt.toLowerCase()) ||
        d.carmodelid.toLowerCase().includes(searchtextt.toLowerCase()) ||
        d.gear.toLowerCase().includes(searchtextt.toLowerCase()) ||
        d.fueltypeid.toLowerCase().includes(searchtextt.toLowerCase())
    );
    props.parentCallback(filterdlist);
  };

  return (
    <div>
      <div className="car-searchbar">
        {" "}
        <button onClick={() => OnSearch()}>
          <img src="assets/images/search-icon.png" alt="" />
        </button>
        <input type="text" placeholder="search here..." onChange={OnSearch} />
      </div>
    </div>
  );
}

export default Search;
