import React, { useEffect, useState } from "react";
import Header from "./Header";
import Loader from "./Utils/Loader";

function Supportpage(props) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.src =
      "https://whatsapp.pcubeweb.com/install-widget/bundle.js?key=65e7e2ad-97b0-4755-9cef-59e23cea9889";
    script.defer = true;
    script.setAttribute("data-active", "");
    script.setAttribute("data-widget-type", "group");
    script.setAttribute("data-person", "3319d4e2-c5a2-45b5-aa01-3b717e019fc5");

    // Append the script to the document body
    document.body.appendChild(script);
    setLoading(false);
    // Clean up the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []); // Empty dependency array ensures the effect runs only once

  return (
    <div>
      <Header />
      {loading ? <Loader /> : ""}
      <div data-active id="whatapp-people-widget"></div>
    </div>
  );
}

export default Supportpage;
