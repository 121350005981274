import React , { useEffect, useState }from 'react';
import Header from "./Header";
import Loader from "./Utils/Loader";

function CarValuation(props) {
   
    const [input, setInput] = useState({});
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
 
    const handleChange = (e) => {
        let value = e.target.value;
        setInput((prevInput) => ({
          ...prevInput,
          [e.target.name]: value,
        }));
      };
    
      const Validate = () => {
        let errors = {};
        let isValid = true;
        if (!input["current"]) {
          isValid = false;
          errors["current"] = "This feild is required";
        }
        if (!input["New"]) {
          isValid = false;
          errors["New"] = "This feild is required";
        }
        setErrors(errors);
    
        return isValid;
      };

    return (
       <div>
        {loading?<Loader/>:null}
      <Header />
      <div
        className="content m-4 px-4 pb-4"
        style={{
          boxShadow: "box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px",
          borderRadius: "10px",
        }}
      >
        <div className="text-center">
          <h2 className="page-title">Car Valuation Calculator</h2>
        </div>
        <div className="form-div">
          <label className="form-label">Ex -showroom Price</label>
          <input
            type="text"
             className="form-control"
             name="Exprice"
             onChange={handleChange}
          />
          <span className="text-danger">{errors.Exprice}</span>
        </div>

        <div className="form-div">
          <label className="form-label">Depreciated Year </label>
          <input
            type="password"
            className="form-control"
            name="year"
            id="year"
            // value={input.Mobileno}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.year}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Refurbishment cost </label>
          <input
            type="text"
            className="form-control"
            name="Refurbishment"
            id="Refurbishment"
            // value={input.Mobileno}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.Refurbishment}</span>
        </div>
        <div className="form-div">
          <label className="form-label">Ownership serial no</label>
          <input
            type="password"
            className="form-control"
            name="year"
            id="passwordtxt"
            // value={input.Mobileno}
            onChange={handleChange}
          />
          <span className="text-danger">{errors.year}</span>
        </div>
        
        <div className="text-center pt-3">
          {/* <button type="submit" className="submitbtn" onClick={handleSubmit}>
            Submit
          </button> */}
        </div>
      </div>
    </div>
    );
}

export default CarValuation;