import React, { useEffect, useState } from "react";
import Header from "../Header";
import Loader from "../Utils/Loader";
import { useNavigate, Link } from "react-router-dom";
import {Toast,saveurl,fetchDataFromApi, Userlogin} from "../Utils/general-function";

function Carstationary(props) {
  const [loading, setLoading] = useState(true);
  const [List, setList] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchdata = async () => {
      if (!Userlogin()) {
        navigate("/Login");
        return;
      }
      try {
        const pdflist = await fetchDataFromApi(
          "/api/PdfBranding/AllPdfBranding"
        );
        if (
          pdflist.Message === "Authorization has been denied for this request."
        ) {
          localStorage.clear();
          navigate("/Login");
          return;
        }
        setList(pdflist.Data || []);
        setLoading(false);
      } catch (error) {
        console.log("Exception Found :",error);
      }
    };
    fetchdata();
  }, [navigate]);

  const OnDownloadDoc = (id) => {
    setLoading(true);
    const header = {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
      },
    };
    fetch(
      process.env.REACT_APP_API_ENDPOINT +
        "/api/PdfBranding/DynamicDraganddropPDF?uploadId=" +
        id,
      header
    )
      .then((response) => response.json())
      .then((res) => {
        saveurl(res.Data.urlvalue, res.Data.DownloadFilename).then((res) => {
          setLoading(false);
        });
      });
  };

  return (
    <div>
      <Header />
      {loading ? <Loader /> : null}

      <div className="content">
        <div className="text-center">
          <h2 className="page-title">Stationery</h2>
        </div>
        {List.length === 0 ? (
          <div className="text-center mt-4">
            <h3 className="car-docstitle border-0">No Documents found!</h3>
          </div>
        ) : (
          List.map((list) => (
            <div className="car-docsdiv" key={list.Rand}>
              <div className="d-flex align-items-center flex-nowrap">
                <img src="assets/images/car-doc.png" alt="" />
                <div className="flex-grow-1 px-3">
                  <h3>{list.Name}</h3>
                </div>
                <div className="car-docscenter">
                  <button
                    className="cardoc-btn"
                    onClick={(e) => OnDownloadDoc(list.Id)}
                  >
                    <img
                      src="assets/images/download-icon.svg"
                      alt="Download"
                      style={{ width: "32px" }}
                    />
                  </button>
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Carstationary;
