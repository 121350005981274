import React, { useEffect, useState } from "react";
import { Userlogin, fetchDataFromApi } from "./Utils/general-function";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Header from "./Header";
import Loader from "./Utils/Loader";
import Search from "./Search";
import RemainingPageTitle from "./Utils/RemainingPageTitle";
import NoCars from "./Utils/NoCars";

function Soldunsold(props) {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const status = location.state.status;
  const [Carlist, setCarlist] = useState([]);
  const [originalList, setOriginalList] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchdata = async () => {
      if (!Userlogin()) {
        navigate("/Login");
        return;
      }
      try {
        const cardata = await fetchDataFromApi(
          "/api/Carmaster/Searchcar?carstatus=" + status
        );
        if (
          cardata.Message === "Authorization has been denied for this request."
        ) {
          localStorage.clear();
          navigate("/Login");
          return;
        }
        setCarlist(cardata.Data);
        setOriginalList(cardata.Data);
        setLoading(false);
      } catch (error) {
        console.log("Exception found", error);
        setLoading(false);
      }
    };

    fetchdata();
  }, [navigate, status]);

  const handleCallback = (childData) => {
    setLoading(true);
    setCarlist(childData);
    setLoading(false);
  };
  return (
    <div>
      <Header />
      <div className="page-space" style={{ marginBottom: "50px" }}>
        <Search dataFromParent={originalList} parentCallback={handleCallback} />
        {loading ? <Loader /> : null}
        {Carlist.length === 0 ? (
          <NoCars/>
        ) : (
          Carlist.map((data, i) => (
            <div className="car-div mt-4" key={data.Rand}>
              <div className="car-radius">
                <img src={data.carphoto} className="w-100" alt="" />
                <div className="car-smdetails">
                  <span className="abs-span">{data.fueltypeid}</span>

                  <RemainingPageTitle dataFromParent={data} />

                  <div className="car-km justify-content-between">
                    <div>
                      <img src="assets/images/car-num.svg" alt="" />
                      <span>{data.carnumber}</span>
                      <img
                        src="assets/images/car-km.svg"
                        alt=""
                        className="ms-2"
                      />
                      <span>{data.km}</span>
                      {data.Carage && (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="21"
                            viewBox="0 0 357.102 357.102"
                            className="ms-2"
                          >
                            <g>
                              <path
                                d="M192.349 13.8c-90.484 0-164.387 73.614-164.741 164.096l-.007 1.74c0 3.442-2.359 5.117-7.013 5.117H5.91c-2.831 0-4.688.974-5.517 2.756-1.241 2.666.784 5.613 1.616 6.64l39.939 63.027.243.331c1.074 1.224 2.489 1.929 3.882 1.929 1.134 0 2.782-.437 4.237-2.596l39.441-62.379c.918-1.075 3.102-4.123 1.872-6.864-.825-1.84-2.751-2.844-5.724-2.844H71.05c-4.432 0-6.998-1.062-6.998-6.853 0-70.426 57.554-127.686 128.297-127.686 70.749 0 128.308 57.574 128.308 128.321 0 70.748-57.559 128.314-128.308 128.314-18.913 0-37.12-4.01-54.114-11.928a18.059 18.059 0 0 0-7.684-1.707c-7.063 0-13.552 4.135-16.533 10.532-4.241 9.11-.283 19.972 8.823 24.214 21.852 10.18 45.237 15.342 69.508 15.342 90.845 0 164.753-73.907 164.753-164.751 0-90.844-73.908-164.751-164.753-164.751z"
                                fill="#ff002a"
                                opacity="1"
                                data-original="#000000"
                              ></path>
                              <path
                                d="M186.553 98.752c-8.284 0-15 6.716-15 15v63c0 7.883 4.452 16.048 11.081 20.319l54.797 35.291a14.924 14.924 0 0 0 8.108 2.392c4.929-.001 9.757-2.428 12.624-6.88 4.486-6.965 2.477-16.247-4.488-20.732l-52.122-33.569v-59.82c0-8.285-6.716-15.001-15-15.001z"
                                fill="#ff002a"
                                opacity="1"
                                data-original="#000000"
                              ></path>
                            </g>
                          </svg>
                          <span>{data.Carage} days </span>
                        </>
                      )}
                    </div>
                    <div className="d-inline-flex align-items-center"></div>
                  </div>
                </div>
                <div className="two-btns">
                  <Link
                    to={"/CarDetail"}
                    state={{ details: data }}
                    className="red w-100"
                  >
                    More Details
                  </Link>
                  {/* <a
                    href={null}
                    className="black"
                    onClick={(e) =>
                      Addtocatlogue(data.carid, data.carnumber, data.Cateloge)
                    }
                  >
                    {data.Cateloge === "No"
                      ? "Add To Catalogue"
                      : "Remove From Catlogue"}
                  </a> */}
                </div>
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default Soldunsold;
