import { toast } from "react-toastify";
import CryptoJS from "crypto-js";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
export function Userlogin() {
  if (localStorage.getItem("ca1rt6_t") === null) {
    return false;
  } else {
    return true;
  }
}
export async function saveurl(url, name) {
  const blob = await (await fetch(url)).blob();
  await saveAs(blob, name);
}

export function Toast(succ_msg, msg, err_msg) {
  if (succ_msg === true || succ_msg === "true") {
    toast.success(msg);
  } else {
    toast.error(err_msg);
  }
}
export const urltobase64 = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve, reject) => {
    reader.onloadend = () => {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.onerror = (error) => reject(error);
  });
};

export async function Shareurl(url, text, title) {
  // if (navigator.share) {
  //   try {
  //     await navigator.share({
  //       title: title,
  //       text: text,
  //       url: url,
  //     });
  //     console.log('Content shared successfully');
  //   } catch (error) {
  //     console.error('Error sharing content:', error);
  //   }
  // } else {
  //   // Fallback if navigator.share is not supported
  //   alert('Web Share API is not supported in your browser. Please copy the URL manually.');
  //   navigator.clipboard.writeText(url)
  //     .then(() => alert('URL copied to clipboard'))
  //     .catch((error) => console.error('Error copying URL to clipboard:', error));
  // }

  if (navigator.share) {
    try {
      await navigator.share({
        url: url,
      });
      console.log("Share was successful.");
    } catch (error) {
      console.log("Sharing failed", error);
    }
  } else {
    alert("Your system doesn't support the Web Share APIs.");
  }
}

export async function getCarCompanies() {
  let res = [];
  await fetch(process.env.REACT_APP_API_ENDPOINT + "/api/Common/companydata")
    .then((response) => response.json())
    .then((res) => {
      res = res.Data;
    });
  return res;
}

export function OnLogout() {
  localStorage.clear();
  Toast("true", "Logout Successfully", "false");
  window.location.href = "/";
}
export function Phonepe(amount, orderid) {
  var payload = {
    merchantId: `PGTESTPAYUAT`,
    merchantTransactionId: orderid,
    merchantUserId: "MUIE2122",
    amount: parseInt(amount) * 100,
    redirectUrl: "https://localhost:3000",
    redirectMode: "POST",
    callbackUrl: "https://localhost:3000",
    mobileNumber: "9769111222",
    paymentInstrument: {
      type: "PAY_PAGE",
    },
  };
  const base64 = btoa(JSON.stringify(payload));
  const sign = base64 + `/pg/v1/pay099eb0cd-02cf-4e2a-8aca-3e6c6aff0399`; // Modify this accordingly based on your API
  var X_VERIFY = signRequest(sign) + "###1";
  console.log("X_VERIFY", X_VERIFY);

  const requestBody = {
    request: base64,
  };
  var PhonePeGatewayURL = "/apis/pg-sandbox/pg/v1/pay";
  const options = {
    method: "POST",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
      "X-VERIFY": X_VERIFY,
    },
    body: JSON.stringify({
      request: base64,
    }),
  };

  fetch("https://api-preprod.phonepe.com/apis/pg-sandbox/pg/v1/pay", options)
    .then((response) => response.json())
    .then((response) => {
      window.location.assign(response.data.instrumentResponse.redirectInfo.url);
      console.log(response.data.instrumentResponse.redirectInfo.url);
    })
    .catch((err) => console.error(err));
}

export async function GetDealerdata() {
  let Data = {};
  const header = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
    },
  };
  await fetch(
    process.env.REACT_APP_API_ENDPOINT + "/api/BrandingProfile/All",
    header
  )
    .then((response) => response.json())
    .then((res) => {
      if (res.Success === true) {
        Data = res.Data[0];
      }
    });
  return Data;
}

export function signRequest(payload) {
  // Assuming you are using CryptoJS for hashing
  const hashedPayload = CryptoJS.SHA256(payload).toString(CryptoJS.enc.Hex);
  console.log("hashstring" + hashedPayload);
  return hashedPayload;
}

export const ownerDrop = [
  { Value: 1, Text: 1 },
  { Value: 2, Text: 2 },
  { Value: 3, Text: 3 },
  { Value: 4, Text: 4 },
  { Value: 5, Text: 5 },
  { Value: 6, Text: 6 },
  { Value: 7, Text: 7 },
  { Value: 8, Text: 8 },
  { Value: 9, Text: 9 },
  { Value: 10, Text: 10 },
];

export const carstatus = [
  { Value: "Coming Soon", Text: "Coming Soon" },
  { Value: "In-Stock", Text: "In-Stock" },
];

export const DateFormate = (date) => {
  // console.log(date);
  return date.toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
};

export const generatePDFWithTable = (dataArray) => {
  // Create a new PDF document
  const pdf = new jsPDF();

  // Define the columns for the table
  const columns = [
    "No",
    "Date",
    "Name",
    "Contact No.",
    "Car Name",
    "Car No.",
    "Remarks",
  ];
  const title = localStorage.getItem("Ca1nioamed");
  // Define the rows for the table
  const rows = dataArray.map((item, index) => [
    index + 1,
    item.createdate,
    item.Name,
    item.Contactno,
    item.Modelyear + " " + item.carname + " " + item.variantname,
    item.Carno,
    item.nRemarks,
  ]);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  pdf.text(title, pdfWidth / 2, 10, { align: "center" });
  // Add the table to the PDF document
  pdf.autoTable({
    head: [columns],
    body: rows,
  });

  // Save the PDF with a filename
  pdf.save("carleads" + new Date().toJSON().slice(0, 13) + ".pdf");
};

export function base64ToBlob(base64) {
  const base64Data = base64.split(",")[1];
  const binaryString = atob(base64Data);
  const uint8Array = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i);
  }
  return new Blob([uint8Array], { type: "image/jpeg" });
}

export async function fetchDataFromApi(url) {
  const header = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("ca1rt6_t"),
    },
  };
  const response = await fetch(
    process.env.REACT_APP_API_ENDPOINT + url,
    header
  );

  if (!response.ok) {
    handleFetchError(response.status);
  }
  return await response.json();
}

const handleFetchError = (errorMessage) => {
  console.error("ERROR", errorMessage);
  // Handle or display the error as needed
};

// For custom styling on dropdown arrow of react slelect
export const customStyles = {
  dropdownIndicator: (base, state) => ({
    ...base,
    transition: "transform 0.25s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
  }),
};

export function wpotpapi() {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("authkey", "421315ADYwJxOmuU663daadeP1");

  var raw = JSON.stringify({
    integrated_number: "919227030193",
    content_type: "template",
    payload: {
      messaging_product: "whatsapp",
      type: "template",
      template: {
        name: "send_code",
        language: {
          code: "en",
          policy: "deterministic",
        },
        namespace: null,
        to_and_components: [
          {
            to: ["7069905525"],
            components: {
              body_1: {
                type: "text",
                value: "Eden's Motor",
              },
              body_2: {
                type: "text",
                value: "1234",
              },
            },
          },
        ],
      },
    },
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  fetch(
    "https://api.msg91.com/api/v5/whatsapp/whatsapp-outbound-message/bulk/",
    requestOptions
  )
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
}

export function posttocarthipage(data) {
  const raw = JSON.stringify({
    message: `${data?.modelyear} ${data?.companyid} ${data?.carmodelid}${
      data?.variantid
    }
  👉Get more details by clicking on this below link🔗
     http://m.carthi.in/#/CatDetails/${data.Rand}/${localStorage.getItem(
      "Ca1itd"
    )}/W_hB`,
    channel: "instagram",
    channelid: 17841469669087996,
    photo: data.carphoto,
    trackableLinks:true
  });

  var requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: raw,
  };

  fetch(
    "https://api.vbout.com/1/socialmedia/addpost.json?key=2572483423306831136470432",
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => console.log(result))
    .catch((error) => console.log("error", error));
}
export function getOrdinal(num) {
  const suffixes = ["th", "st", "nd", "rd"];
  const remainder = num % 100;
  return (
    num +
    (suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0])
  );
}

export const generatestocklist = async(dataArray) => {
  // Create a new PDF document
  const pdf = new jsPDF();
const url=await urltobase64("https://apps.carthi.in/Admin/assets/images/app_logo.png");

  // Define the columns for the table
  const columns = [
    "No",
    "Vehicle",
    "varient",
    "Year",
    "Fuel",
    "Transmission",
    "Owner",
    "Km",
    "Price",
  ];
  const title = localStorage.getItem("Ca1nioamed") ;
  const subtitle="Stock List";
  // Define the rows for the table
  const rows = dataArray.map((item, index) => [
    index + 1,
    item.carmodelid,
    item.variantid,
    item.modelyear,
    item.fueltypeid,
    item.gear,
    getOrdinal(item.owner),
    item.km,
    item.nSaleprice,
  ]);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight=pdf.internal.pageSize.getHeight();
  pdf.setFontSize(12);
  
  pdf.text(title, pdfWidth / 2, 12, { align: "center" });
  pdf.text(subtitle, pdfWidth / 2, 20, { align: "center" });
   
  pdf.autoTable({
    head: [columns],
    body: rows,
    theme: "striped",
    margin: { top: 25, bottom: 30 },

    styles: {
      halign: "center", // Horizontal alignment for text
    },

    headStyles: {
      fillColor: [237, 28, 36],
      textColor: [255, 255, 255],
      fontStyle: "bold",
    },
    didDrawPage: (data) => {
      // Footer content for every page
      const footerText = `Report Created By:`;

      
      // Set footer-specific styles
      pdf.setFontSize(8); 
      pdf.setTextColor("#949191"); 
      
      
      pdf.text(footerText, pdfWidth / 2, pdfHeight - 20, { align: "center" });

       // Add image to the footer (center-aligned)
      const imgWidth = 31; // Adjust image width
      const imgHeight = 14; // Adjust image height
      const imgX = (pdfWidth - imgWidth) / 2; // Calculate X to center the image
      const imgY = pdfHeight - 18;

       pdf.addImage(url, 'PNG',imgX, imgY, imgWidth, imgHeight);
  },
  });


   pdf.save("Mystocklist" + new Date().toJSON().slice(0, 13) + ".pdf");
};
export const generateownerist = async(dataArray) => {
  // Create a new PDF document
  const pdf = new jsPDF();
const url=await urltobase64("https://apps.carthi.in/Admin/assets/images/app_logo.png");

  // Define the columns for the table
  const columns = [
    "No",
    "Car Number",
    "Model",
    "Buyer",
    "Seller"
  ];
  const title = localStorage.getItem("Ca1nioamed") ;
  const subtitle="Buyer/Seller Report";
  // Define the rows for the table
  const rows = dataArray.map((item, index) => [
    index + 1,
    item.Carno,
    item.carmodelname,
    item.nDetails.split("|")[0],
    item.nDetails.split("|")[1],
  ]);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight=pdf.internal.pageSize.getHeight();
  pdf.setFontSize(12);
  
  pdf.text(title, pdfWidth / 2, 12, { align: "center" });
  pdf.text(subtitle, pdfWidth / 2, 20, { align: "center" });
   
  pdf.autoTable({
    head: [columns],
    body: rows,
    theme: "striped",
    margin: { top: 25, bottom: 30 },

    styles: {
      halign: "center", // Horizontal alignment for text
    },

    headStyles: {
      fillColor: [237, 28, 36],
      textColor: [255, 255, 255],
      fontStyle: "bold",
    },
    didDrawPage: (data) => {
      // Footer content for every page
      const footerText = `Report Created By:`;

      
      // Set footer-specific styles
      pdf.setFontSize(8); 
      pdf.setTextColor("#949191"); 
      
      
      pdf.text(footerText, pdfWidth / 2, pdfHeight - 20, { align: "center" });

       // Add image to the footer (center-aligned)
      const imgWidth = 31; // Adjust image width
      const imgHeight = 14; // Adjust image height
      const imgX = (pdfWidth - imgWidth) / 2; // Calculate X to center the image
      const imgY = pdfHeight - 18;

       pdf.addImage(url, 'PNG',imgX, imgY, imgWidth, imgHeight);
  },
  });


   pdf.save("Buyersellerlist" + new Date().toJSON().slice(0, 13) + ".pdf");
};

