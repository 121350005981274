import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  generatestocklist,
  fetchDataFromApi,
  Userlogin,
} from "../Utils/general-function";
import Loader from "../Utils/Loader";

function Stocklist(props) {
  const [stock, setStock] = useState([]);
  const[loading,setLoading]=useState(true)
  const navigate = useNavigate();
  useEffect(() => {
    const fetchdata = async () => {
      if (!Userlogin()) {
        navigate("/Login");
        return;
      }
      try {
        const cardata = await fetchDataFromApi(
          "/api/Carmaster/Searchcar?carstatus=Unsold"
        );
        if (
          cardata.Message === "Authorization has been denied for this request."
        ) {
          localStorage.clear();
          navigate("/Login");
          return;
        }
        setStock(cardata.Data);
         setLoading(false);
      } catch (error) {
        console.log("Exception found", error);
        setLoading(false);
      }
    };

    fetchdata();
  }, []);
  return (
    <div>
       {loading? <Loader/>:null}
      <div className="car-docsdiv">
        <div className="d-flex align-items-center flex-nowrap">
          <img src="assets/images/car-doc.png" alt="" />
          <div className="flex-grow-1 px-3">
            <h3>Stocklist Report</h3>
          </div>
          <div className="d-inline-flex flex-nowrap">
            <button
              className="cardoc-btn"
              onClick={(e) => generatestocklist(stock)}>
              <img src="assets/images/download-icon.svg" alt="Download"  style={{ width: "32px" }}/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stocklist;
