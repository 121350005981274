import React, { useEffect, useState, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import { OnLogout, Userlogin } from "./Utils/general-function";
import useApiData from "./useApiData";

const Header = React.memo((props) => {
  const [menuOpen, setMenuOpen] = useState(false);
  // const data=useApiData();
  // console.log(data,"Header");
  const navigate = useNavigate();
  const menuRef = useRef(null);

  //  for not showing menubar in user with not upodeted profiile
  let nCity = "data";

  if (props.updated === "") {
    nCity = props.updated;
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
        const btn = document.querySelector(".sharbtnpos");
        if (btn) {
          btn.style.display = "inline-flex";
        }
      }
    };

    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (!Userlogin()) {
      navigate("/Login");
    }
  }, []);

  const handleToggleMenu = () => {
    setMenuOpen(!menuOpen);
    const btn = document.querySelector(".sharbtnpos");
    if (btn) {
      btn.style.display = "none";
    }
  };

  const handleMenuClose = () => {
    setMenuOpen(false);
    const btn = document.querySelector(".sharbtnpos");
    if (btn) {
      btn.style.display = "inline-flex";
    }
  };

  return (
    <div className="fixheader">
      <header className="d-flex justify-content-between align-items-center">
        <div className="test d-flex flex-row align-items-center">
          {nCity && (
            <div className="menubtn" onClick={handleToggleMenu} ref={menuRef}>
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}

          <a href="/" className="logodiv">
            <img src="assets/images/logo.png" alt="" />
          </a>
        </div>
        <Link to="/Supportpage" style={{ marginRight: "15px" }}>
          {/* <svg
            xmlns="http://www.w3.org/2000/svg"
            width="45"
            height="45"
            viewBox="0 0 512 512"
          >
            <g>
              <g data-name="35 Need Help">
                <circle
                  cx="256"
                  cy="256"
                  r="200"
                  fill="#f80000"
                  opacity="1"
                  data-original="#b89dd6"
                ></circle>
                <path
                  fill="#f10000"
                  d="M356.71 479.41A244.11 244.11 0 0 1 256 501C120.69 501 11 391.31 11 256a243.84 243.84 0 0 1 35.13-126.48c39.27 161.55 157.01 292.4 310.58 349.89z"
                  opacity="1"
                  data-original="#a894c4"
                ></path>
                <circle
                  cx="256"
                  cy="256"
                  r="195"
                  fill="#f7f5fb"
                  opacity="1"
                  data-original="#f7f5fb"
                ></circle>
                <path
                  fill="#fff"
                  d="M289.16 448.17A194.92 194.92 0 0 1 256 451c-107.53 0-195-87.47-195-195a194.59 194.59 0 0 1 8.09-55.66 501.86 501.86 0 0 0 220.07 247.83z"
                  opacity="1"
                  data-original="#e4e3e8"
                ></path>
                <path
                  d="M256 507A251 251 0 0 1 5.21 266.25a6 6 0 1 1 12-.48A238.27 238.27 0 0 0 256 495c131.79 0 239-107.22 239-239S387.79 17 256 17C138.71 17 37.12 104.31 19.68 220.1a6 6 0 0 1-11.87-1.79A251 251 0 1 1 256 507z"
                  fill="#FF2525"
                  opacity="1"
                  data-original="#000000"
                ></path>
                <path
                  d="M11.17 253.11H11a6 6 0 0 1-5.78-6.21c.11-3.16.28-6.27.5-9.22a6 6 0 1 1 12 .88c-.21 2.81-.37 5.76-.48 8.77a6 6 0 0 1-6.07 5.78zM256 457c-110.83 0-201-90.17-201-201S145.17 55 256 55s201 90.17 201 201-90.17 201-201 201zm0-390C151.79 67 67 151.79 67 256s84.79 189 189 189 189-84.79 189-189S360.21 67 256 67z"
                  fill="#FF2525"
                  opacity="1"
                  data-original="#000000"
                ></path>
                <path
                  d="M311.2 246.45h2.35a43.18 43.18 0 1 0 0-86.35h-2.35a11.19 11.19 0 0 0-11.2 11.18v64a11.19 11.19 0 0 0 11.2 11.17zm.8-74.35h1.53a31.18 31.18 0 1 1 0 62.35H312zM250.63 246.45h32.66a6 6 0 0 0 0-12h-29.08v-25.18h29.08a6 6 0 0 0 0-12h-29.08V172.1h29.08a6 6 0 0 0 0-12h-32.66a8.43 8.43 0 0 0-8.42 8.42V238a8.43 8.43 0 0 0 8.42 8.45zM233 240.45a6 6 0 0 0-6-6h-29.1v-25.18H227a6 6 0 0 0 0-12h-29.1V172.1H227a6 6 0 0 0 0-12h-32.68a8.43 8.43 0 0 0-8.42 8.42V238a8.43 8.43 0 0 0 8.42 8.42H227a6 6 0 0 0 6-5.97zM174.69 166.1a6 6 0 1 0-12 0v54.51l-32.82-56.74a7.56 7.56 0 0 0-14.1 3.79v72.79a6 6 0 0 0 12 0v-56.24l33.91 58.62a7 7 0 0 0 13-3.5zM214.07 273.15a6 6 0 0 0 0-12H181.4a8.43 8.43 0 0 0-8.42 8.42v69.51a8.43 8.43 0 0 0 8.42 8.42h32.67a6 6 0 0 0 0-12H185v-25.17h29.09a6 6 0 0 0 0-12H185v-25.18zM157.47 261.15a6 6 0 0 0-6 6v31.18h-23.7v-31.18a6 6 0 0 0-12 0v74.35a6 6 0 0 0 12 0v-31.17h23.7v31.17a6 6 0 0 0 12 0v-74.35a6 6 0 0 0-6-6zM265.21 335.5H243v-68.35a6 6 0 0 0-12 0v68.74a11.62 11.62 0 0 0 11.61 11.61h22.65a6 6 0 1 0 0-12zM307.5 261.15h-20.57a6 6 0 0 0-6 6v74.35a6 6 0 0 0 12 0v-27.22h14.57a26.57 26.57 0 1 0 0-53.13zm0 41.13h-14.57v-29.13h14.57a14.57 14.57 0 1 1 0 29.13zM396.23 287.14a30.29 30.29 0 0 0-59.73-7.21 6 6 0 0 0 11.66 2.83 18.29 18.29 0 0 1 36.07 4.38 18.51 18.51 0 0 1-12.94 17.59c-.14 0-.27.1-.4.15a6 6 0 0 0-5.29 5.95v16.36a6 6 0 0 0 12 0v-12a30.2 30.2 0 0 0 18.63-28.05zM371.6 336.68a6 6 0 0 0-6 6v3.22a6 6 0 0 0 12 0v-3.22a6 6 0 0 0-6-6z"
                  fill="#030303"
                  opacity="1"
                  data-original="#000000"
                ></path>
              </g>
            </g>
          </svg> */}

          <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width="50"
            height="50"
            x="0"
            y="0"
            viewBox="0 0 90 90"
          >
            <g>
              <g fillRule="evenodd" clipRule="evenodd">
                <path
                  d="M46.024 12.131a31.3 31.3 0 0 0-18.437 5.256A31.31 31.31 0 0 0 14.925 34.67c-.557-.068-1.224-.038-2.016.186-2.957.84-5.18 3.262-6.18 5.396-1.298 2.781-1.845 6.46-1.268 10.256.573 3.785 2.172 6.905 4.172 8.842 2.006 1.938 4.271 2.568 6.521 2.095 3.35-.715 5.011-1.25 4.542-4.37l-2.27-15.13a26.604 26.604 0 0 1 11.786-20.636 26.588 26.588 0 0 1 30.984 1.016A26.599 26.599 0 0 1 71.571 41.98l-1.588 10.583A26.59 26.59 0 0 1 47.5 69.923h-6.766a3.145 3.145 0 0 0-3.152 3.15v1.662a3.145 3.145 0 0 0 3.152 3.151h8.531a3.14 3.14 0 0 0 3.145-3.15v-.87A31.408 31.408 0 0 0 71.114 60.72l2.733.725c2.224.577 4.516-.158 6.521-2.095 2-1.938 3.598-5.057 4.172-8.842.58-3.797.016-7.469-1.266-10.256-1.287-2.787-3.204-4.557-5.417-5.192-.927-.267-1.933-.365-2.796-.39a31.276 31.276 0 0 0-10.99-16.085 31.29 31.29 0 0 0-18.047-6.454z"
                  fill="#ff002a"
                  opacity="1"
                  data-original="#000000"
                ></path>
                <path
                  d="M56.01 39.35a4.092 4.092 0 0 1 4.087 4.088 4.095 4.095 0 0 1-4.088 4.088 4.093 4.093 0 0 1-4.094-4.088 4.091 4.091 0 0 1 4.094-4.089zm-11.01 0a4.088 4.088 0 1 1-4.09 4.089 4.086 4.086 0 0 1 4.09-4.09zm-11.007 0a4.089 4.089 0 1 1 0 8.176 4.089 4.089 0 0 1 0-8.177zM45 21.786c-11.99 0-21.652 9.344-21.652 21.651 0 5.911 2.235 11.135 5.876 14.968l-1.292 5.792c-.426 1.906.896 3.188 2.61 2.234l5.655-3.155A22.035 22.035 0 0 0 45 65.09c11.994 0 21.65-9.338 21.65-21.65 0-12.308-9.656-21.652-21.65-21.652z"
                  fill="transparent"
                  stroke="#ff002a"
                  opacity="1"
                  data-original="#000000"
                ></path>
              </g>
            </g>
          </svg>
        </Link>
      </header>
      <div id="overlay" style={{ right: menuOpen ? "0" : "100%" }}></div>

      <div className={`menubar ${menuOpen ? "open" : ""}`}>
        <span
          id="closemenu"
          onClick={handleMenuClose}
          style={{ top: menuOpen ? "0" : "-30px" }}
        >
          <span className="material-symbols-outlined">close</span>
        </span>
        <div className="user-info">
          <a href="#/Profile" className="d-flex flex-row align-items-center">
            <span>Hello,{localStorage.getItem("Ca1nioamed")}</span>
          </a>
        </div>
        <ul id="vertical-menu">
          <li onClick={handleMenuClose}>
            <Link to="/">
              <img src="assets/images/dashboard12.png" alt="" />
              <div>
                <span>Dashboard</span>
                <p>Centralized cars hub</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/CarInquiry">
              <img src="assets/images/add-car12.png" alt="" />
              <div>
                <span>Add vehicle</span>
                <p>Listing your vehicle</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/MyCar">
              <img src="assets/images/car12.png" alt="" />
              <div>
                <span>My vehicles</span>
                <p>My vehicle portfolio</p>
              </div>
            </Link>
          </li>

          <li onClick={handleMenuClose}>
            <Link to={`/Catlogue/${localStorage.getItem("Ca1itd")}`}>
              <img src="assets/images/catalogue12.png" alt="" />
              <div>
                <span>My catalogue</span>
                <p>Explore your portfolio</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/CreateStatus">
              <img src="assets/images/status12.png" alt="" />
              <div>
                <span>Create status</span>
                <p>share your vehicle status</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/GenerateQr">
              <img src="assets/images/qr-code12.png" alt="" />
              <div>
                <span>Vehicle QRs</span>
                <p>get vehicle details by scan</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/SearchDoc">
              <img src="assets/images/audit1.png" alt="" />
              <div>
                <span>Vehicle documents</span>
                <p>Find vehicle documents</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/Leaddata">
              <img src="assets/images/list1.png" alt="" />
              <div>
                <span>Leads</span>
                <p>Get your leadlist</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/Addlead">
              <img src="assets/images/add-user12.png" alt="" />
              <div>
                <span>Add lead</span>
                <p>Add your lead</p>
              </div>
            </Link>
          </li> <li onClick={handleMenuClose}>
            <Link to="/Stockrecord/Add">
              <img src="assets/images/addbuy.png" alt="" />
              <div>
                <span>Buyer/Seller entry</span>
                <p>Add new buyer or seller entry </p>
              </div>
            </Link>
          </li>
          {/* <li onClick={handleMenuClose}>
            <Link to="/SearchBuyerSeller">
              <img src="assets/images/note.png" alt="" />
              <div>
                <span>Buyer/Seller lookup</span>
                <p>Update  buyer or seller record</p>
              </div>
            </Link>
          </li> */}
          <li onClick={handleMenuClose}>
            <Link to="/Report">
              <img src="assets/images/report.png" alt="" />
              <div>
                <span>Reports</span>
                <p>View portfolio analytics</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/Service">
              <img src="assets/images/service12.png" alt="" />
              <div>
                <span>Services</span>
                <p>Get vehicle services</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/AllImages">
              <img src="assets/images/all-images.png" alt="" />
              <div>
                <span>Marketing post</span>
                <p>Get your social-media post</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/Profile">
              <img src="assets/images/profile12.png" alt="" />
              <div>
                <span>My Profile</span>
                <p>Update Your profile</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to="/Changepassword">
              <img src="assets/images/security12.png" alt="" />
              <div>
                <span>Change password</span>
                <p>Change your password</p>
              </div>
            </Link>
          </li>
          <li onClick={handleMenuClose}>
            <Link to={null} onClick={(e) => OnLogout()}>
              <img src="assets/images/logout1.png" alt="" />
              <div>
                <span>Logout</span>
                <p>Sign out your app</p>
              </div>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
});

export default Header;

// const handleToggleMenu = () => {
//   setIsMenuOpen(!isMenuOpen);
//   const overlay = document.querySelector("#overlay");
//   const closemenu = document.querySelector("#closemenu");

//   if (isMenuOpen) {
//     overlay.style.right = "0";
//     closemenu.style.top = "0";
//   }
// };
// document.querySelector('meta[property="og:title"]').setAttribute("content", "Carrrr");

// const handleCloseMenu = () => {
//   setIsMenuOpen(false);
// };
